<div id="vision-mission" class="py-5">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-4">
                <h2 class="home-title">Hindu College Of Pharmacy</h2>
                <p>We don’t just give students an education and experiences that set them up for success in a career. We
                    help them succeed in their career – to discover a field they’re passionate about and dare to lead
                    it.</p>
            </div>
            <div class="col-12 col-md-8">
                <div class="row">
                    <ul class="nav nav-tabs">
                        <li class="nav-item">
                            <a class="nav-link active" aria-current="page" id="vision-btn" data-bs-toggle="collapse"
                                data-bs-target="#vision" (click)="showCard(1)">
                                <i class="fa-solid fa-eye"></i> &nbsp;Our Vision
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" aria-current="page" id="mission-btn" data-bs-toggle="collapse"
                                data-bs-target="#mission" (click)="showCard(2)">
                                <i class="fa-solid fa-earth-americas"></i> &nbsp;Our Mission
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" aria-current="page" id="objective-btn" data-bs-toggle="collapse"
                                data-bs-target="#objective" (click)="showCard(3)">
                                <i class="fa-solid fa-trophy"></i> &nbsp;Our Objectives
                            </a>
                        </li>
                    </ul>
                </div>

                <div class="row collapse" id="vision">
                    <p class="mt-3">To serve as a worldwide leader and a driving force for advancement in the pharmacy
                        profession, education, and scientific research that enhances health outcomes.</p>
                </div>

                <div class="row collapse" id="mission">
                    <p class="mt-3">To deliver quality pharmaceutical education, promote research and innovation, and
                        prepare ethically driven professionals to excel in healthcare and address global pharmaceutical
                        challenges.</p>
                </div>

                <div class="row collapse" id="objective">
                    <ul class="mt-3" style="padding-left: 2rem;">
                        <li>
                            <p class="mb-0">Commitment to offer quality education.</p>
                        </li>
                        <li>
                            <p class="mb-0">Challenge & motivate students to explore their full potential.</p>
                        </li>
                        <li>
                            <p class="mb-0">Encourage extra-curricular activities among students& develop their overall personality.</p>
                        </li>
                    </ul>
                </div>

            </div>
        </div>
    </div>
</div>