<app-banner></app-banner>

<div id="side-navigation">
    <div class="container-fluid mt-4">
        <div class="row">
            <div class="col-12 col-md-4 col-lg-3 side-menu ps-lg-5">
                <h6>Committees / Cells</h6>
                <ul class="nav flex-column">
                    <li class="nav-item" routerLinkActive="active">
                        <a class="nav-link" routerLink="/cells/admission">Admission Cell</a>
                    </li>
                    <li class="nav-item" routerLinkActive="active">
                        <a class="nav-link" routerLink="/cells/anti-ragging">Anti Ragging Cell</a>
                    </li>
                    <li class="nav-item" routerLinkActive="active">
                        <a class="nav-link" routerLink="/cells/anti-squad">Anti Squad Cell</a>
                    </li>
                    <li class="nav-item" routerLinkActive="active">
                        <a class="nav-link" routerLink="/cells/anti-discrimination">Anti Discrimination Cell</a>
                    </li>
                    <li class="nav-item" routerLinkActive="active">
                        <a class="nav-link" routerLink="/cells/gender">Gender Sensitization Cell</a>
                    </li>
                    <li class="nav-item" routerLinkActive="active">
                        <a class="nav-link" routerLink="/cells/discipline">Discipline Cell</a>
                    </li>
                    <li class="nav-item" routerLinkActive="active">
                        <a class="nav-link" routerLink="/cells/grievance">Grievance Redressal Cell</a>
                    </li>
                    <li class="nav-item" routerLinkActive="active">
                        <a class="nav-link" routerLink="/cells/innovation">Innovation Cell</a>
                    </li>
                    <li class="nav-item" routerLinkActive="active">
                        <a class="nav-link" routerLink="/cells/industry">Institute Industry Cell</a>
                    </li>
                    <li class="nav-item" routerLinkActive="active">
                        <a class="nav-link" routerLink="/cells/icc">Internal Complaint Committee</a>
                    </li>
                    <li class="nav-item" routerLinkActive="active">
                        <a class="nav-link" routerLink="/cells/iqac">Internal Quality Assurance Cell</a>
                    </li>
                    <li class="nav-item" routerLinkActive="active">
                        <a class="nav-link" routerLink="/cells/media">Media Cell</a>
                    </li>
                    <li class="nav-item" routerLinkActive="active">
                        <a class="nav-link" routerLink="/cells/research">Research Cell</a>
                    </li>
                    <li class="nav-item" routerLinkActive="active">
                        <a class="nav-link" routerLink="/cells/harrasment">Sexual Harrasment Cell</a>
                    </li>
                    <li class="nav-item" routerLinkActive="active">
                        <a class="nav-link" routerLink="/cells/sports">Sports Cell</a>
                    </li>
                    <li class="nav-item" routerLinkActive="active">
                        <a class="nav-link" routerLink="/cells/counselor">Students Counselor</a>
                    </li>
                </ul>
            </div>
            <div class="col-md-9">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>