<div id="infrastructure">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <h4 class="card-title">Specialized Lab</h4>
                <div class="row text-center">
                    <div class="col-12 col-md-4 mb-3"><img src="/assets/img/infra/lab1.jpg" class="img-fluid rounded"
                            alt=""></div>
                    <div class="col-12 col-md-4 mb-3"><img src="/assets/img/infra/lab2.jpg" class="img-fluid rounded"
                            alt=""></div>
                    <div class="col-12 col-md-4 mb-3"><img src="/assets/img/infra/lab3.jpg" class="img-fluid rounded"
                            alt=""></div>
                    <div class="col-12 col-md-4 mb-3"><img src="/assets/img/infra/lab4.jpg" class="img-fluid rounded"
                            alt=""></div>
                    <div class="col-12 col-md-4 mb-3"><img src="/assets/img/infra/lab5.jpg" class="img-fluid rounded"
                            alt=""></div>
                    <div class="col-12 col-md-4 mb-3"><img src="/assets/img/infra/lab6.jpg" class="img-fluid rounded"
                            alt=""></div>
                </div>
                <p class="inner-lead">The college has spacious labs with latest equipments to cater the needs of the
                    students.</p>
                <p class="inner-lead">Some of the available sophisticated instruments are:</p>
                <ol>
                    <li>
                        <p class="inner-lead">Digital Dissolution Apparatus</p>
                    </li>
                    <li>
                        <p class="inner-lead">Ultracentrifuge</p>
                    </li>
                    <li>
                        <p class="inner-lead">Stability Chamber</p>
                    </li>
                    <li>
                        <p class="inner-lead">P.L.C.</p>
                    </li>
                    <li>
                        <p class="inner-lead">Brookfield Viscometer</p>
                    </li>
                    <li>
                        <p class="inner-lead">Probe Sonicator</p>
                    </li>
                    <li>
                        <p class="inner-lead">Lyophilizer</p>
                    </li>
                    <li>
                        <p class="inner-lead">Micro Centrifuge</p>
                    </li>
                    <li>
                        <p class="inner-lead">Refrigerated Centrifuge</p>
                    </li>
                    <li>
                        <p class="inner-lead">Tablet Machine (16 Stations)</p>
                    </li>
                    <li>
                        <p class="inner-lead">Homogenizer</p>
                    </li>
                    <li>
                        <p class="inner-lead">Bulk Density Apparatus</p>
                    </li>
                    <li>
                        <p class="inner-lead">FT-IR Spectrophotometer</p>
                    </li>
                    <li>
                        <p class="inner-lead">Double Beam UV Spectroscopy</p>
                    </li>
                    <li>
                        <p class="inner-lead">Hardness Tester</p>
                    </li>
                    <li>
                        <p class="inner-lead">Friability Test Apparatus</p>
                    </li>
                    <li>
                        <p class="inner-lead">Franz Diffusion Cell</p>
                    </li>
                    <li>
                        <p class="inner-lead">Disintegration Tester (6 Basket)</p>
                    </li>
                    <li>
                        <p class="inner-lead">Electric Shaker</p>
                    </li>
                    <li>
                        <p class="inner-lead">Auto Deionizer</p>
                    </li>
                    <li>
                        <p class="inner-lead">Sieve Sets</p>
                    </li>
                    <li>
                        <p class="inner-lead">Laminar Flow</p>
                    </li>
                    <li>
                        <p class="inner-lead">Vortex Mixer</p>
                    </li>
                    <li>
                        <p class="inner-lead">Colorimeter with Eight Filters</p>
                    </li>
                    <li>
                        <p class="inner-lead">Rotary Vacuum Evaporator</p>
                    </li>
                    <li>
                        <p class="inner-lead">O.D. Incubator</p>
                    </li>
                    <li>
                        <p class="inner-lead">Digital Ovens</p>
                    </li>
                    <li>
                        <p class="inner-lead">Refractometer</p>
                    </li>
                    <li>
                        <p class="inner-lead">Redwood Viscometer</p>
                    </li>
                    <li>
                        <p class="inner-lead">Deep Freeze</p>
                    </li>
                    <li>
                        <p class="inner-lead">Chemical Biochemistry Analyzer</p>
                    </li>
                    <li>
                        <p class="inner-lead">Electrophoresis Unit</p>
                    </li>
                    <li>
                        <p class="inner-lead">Conductivity Meter</p>
                    </li>
                    <li>
                        <p class="inner-lead">Digital Balance</p>
                    </li>
                    <li>
                        <p class="inner-lead">Digital Plethysmometer for Measuring Paw Inflammation</p>
                    </li>
                    <li>
                        <p class="inner-lead">Actophotometer</p>
                    </li>
                    <li>
                        <p class="inner-lead">Rota Rod Apparatus</p>
                    </li>
                    <li>
                        <p class="inner-lead">Colony Counter</p>
                    </li>
                    <li>
                        <p class="inner-lead">Radial Senior Rotary Microtome</p>
                    </li>
                    <li>
                        <p class="inner-lead">Binocular Microscope with Various Attachments</p>
                    </li>
                    <li>
                        <p class="inner-lead">Projection Microscopes</p>
                    </li>
                </ol>
            </div>
        </div>
    </div>
</div>