import { Component, Input, OnInit } from '@angular/core';
import { Faculty } from 'src/app/models/faculty.model';

@Component({
  selector: 'app-faculty-item',
  templateUrl: './faculty-item.component.html',
  styleUrls: ['./faculty-item.component.scss']
})
export class FacultyItemComponent implements OnInit {
  @Input() staff:Faculty={
    name:'',
    designation:'',
    qual:'',
    img:'',
    resume: ''
  }
  constructor() { }

  ngOnInit(): void {
  }

}
