import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { AdmissionComponent } from 'src/app/screens/admission/admission.component';
import { CourseComponent } from 'src/app/screens/admission/course/course.component';
import { StatusComponent } from 'src/app/screens/admission/status/status.component';
import { FeeComponent } from 'src/app/screens/admission/fee/fee.component';



@NgModule({
  declarations: [
    AdmissionComponent,
    CourseComponent,
    StatusComponent,
    FeeComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild([
      {
        path: '', component: AdmissionComponent, children: [
          { path: 'course', component: CourseComponent },
          { path: 'status', component: StatusComponent },
          { path: 'fee', component: FeeComponent },
        ]
      }
    ])
  ]
})
export class AdmissionModule { }
