import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gender',
  templateUrl: './gender.component.html',
  styleUrls: ['./gender.component.scss']
})
export class GenderComponent implements OnInit {
  members = [
    {
      srNo: 1,
      name: "Prof. (Dr.) Arun Mittal (Principal)",
      designation: "Chairperson",
      mobile: "9354806431",
      email: "mittalarun3177@gmail.com",
    },
    {
      srNo: 2,
      name: "Dr. Jyoti (Asst. Prof.)",
      designation: "Member Secretary",
      mobile: "9896459487",
      email: "jyoti.a.hcp@gmail.com",
    },
    {
      srNo: 3,
      name: "Mrs. Jyoti (Asst. Prof.)",
      designation: "Member",
      mobile: "8168320422",
      email: "jyoti.j.hcp@gmail.com",
    },
    {
      srNo: 4,
      name: "Ms. Priyanka Sharma (Asst. Prof.)",
      designation: "Member",
      mobile: "9416884336",
      email: "priyankas.hcp@gmail.com",
    },
    {
      srNo: 5,
      name: "Mrs. Shilpa (Clerk)",
      designation: "Member",
      mobile: "8950257914",
      email: "shilpajindal0209@gmail.com",
    },
  ];
  constructor() { }

  ngOnInit(): void {
  }

}
