import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {
news:{imgUrl:string,title:string, description:string, category:string, date:string}[] =[
    {
      imgUrl:'/assets/img/achievement/1.jpg',
      title:'Guest Lecture',
      description:'',
      category:'Events',
      date:'23/Dec/2024'
    },
    {
      imgUrl:'/assets/img/achievement/1.jpg',
      title:'Guest Lecture',
      description:'',
      category:'Events',
      date:'23/Dec/2024'
    },
  ];
  notices:{day:string,month:string,year:string,title:string,noticeFor:string,fileUrl:string}[]=[
    {
      day:'15',
      month:'FEB',
      year:'2024',
      title:'Guidelines regarding Exam Odd Sem',
      noticeFor:'Student',
      fileUrl:''
    },
    {
      day:'13',
      month:'FEB',
      year:'2024',
      title:'Exam Notice',
      noticeFor:'Student',
      fileUrl:''
    },
  ]

  customOptions: OwlOptions = {
    loop: true,
    margin:10,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['<', '>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 3
      }
    },
    nav: true
  }
  constructor() { }

  ngOnInit(): void {
  }

}
