<div id="programs" class="mb-5">
    <div class="container">
        <div class="row">
            <div class="col-12 text-center mb-4">
                <h2 class="home-title">Programs Made For You</h2>
            </div>
        </div>
        <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide>
                <div class="card  ">
                    <div class="row d-flex align-items-center">
                        <div class="col-2">
                            <img src="/assets/svg/courses/1.svg" alt="Arts" class="img-fluid">
                        </div>
                        <div class="col-10">
                            <h3 class="fw-bold">Under Graduate Programs</h3>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-16">
                            <span><i class="fa-solid fa-check-double"></i>B. Pharmacy</span><br><br><br>
                            <a routerLink="/courses/ug">KNOW MORE</a>
                        </div>
                    </div>
                    <div class="main-bg"></div>
                    <div class="bubble-1"></div>
                    <div class="bubble-2"></div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="card ">
                    <div class="row d-flex align-items-center">
                        <div class="col-2">
                            <img src="/assets/svg/courses/3.svg" alt="Commerce" class="img-fluid">
                        </div>
                        <div class="col-10">
                            <h3 class="fw-bold">POST GRADUATE Programs</h3>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <span><i class="fa-solid fa-check-double"></i>M. Pharmacy in Pharmacology<span style="color: white;">*</span></span><br>
                            <span><i class="fa-solid fa-check-double"></i>M. Pharmacy in Pharmaceutics</span><br><br>
                            <a routerLink="/courses/pg">KNOW MORE</a>
                        </div>
                    </div>
                    <div class="main-bg"></div>
                    <div class="bubble-1"></div>
                    <div class="bubble-2"></div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="card ">
                    <div class="row d-flex align-items-center">
                        <div class="col-2">
                            <img src="/assets/svg/courses/2.svg" alt="Science" class="img-fluid">
                        </div>
                        <div class="col-10">
                            <h3 class="fw-bold">Diploma Programs</h3>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <span><i class="fa-solid fa-check-double"></i>D. Pharmacy</span><br><br><br>
                            <a routerLink="/courses/certificate">KNOW MORE</a>
                        </div>
                    </div>
                    <div class="main-bg"></div>
                    <div class="bubble-1"></div>
                    <div class="bubble-2"></div>
                </div>
            </ng-template>

        </owl-carousel-o>
    </div>
</div>