<div id="infrastructure">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <h4 class="card-title">Sports Centre</h4>
                <p class="inner-lead">The college is having excellent indoor sports facility and ground for outdoor
                    sports. Every year annual sports meet is organized. Students participate in various indoor & outdoor
                    games regularly.</p>
            </div>
        </div>
    </div>
</div>