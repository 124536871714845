import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdmissionComponent } from 'src/app/screens/cells/admission/admission.component';
import { AntiRaggingComponent } from 'src/app/screens/cells/anti-ragging/anti-ragging.component';
import { AntiSquadComponent } from 'src/app/screens/cells/anti-squad/anti-squad.component';
import { CellsComponent } from 'src/app/screens/cells/cells.component';
import { CounselorComponent } from 'src/app/screens/cells/counselor/counselor.component';
import { DisciplineComponent } from 'src/app/screens/cells/discipline/discipline.component';
import { GrievanceComponent } from 'src/app/screens/cells/grievance/grievance.component';
import { HarrasmentComponent } from 'src/app/screens/cells/harrasment/harrasment.component';
import { IccComponent } from 'src/app/screens/cells/icc/icc.component';
import { IndustryComponent } from 'src/app/screens/cells/industry/industry.component';
import { InnovationComponent } from 'src/app/screens/cells/innovation/innovation.component';
import { IqacComponent } from 'src/app/screens/cells/iqac/iqac.component';
import { MediaComponent } from 'src/app/screens/cells/media/media.component';
import { ResearchComponent } from 'src/app/screens/cells/research/research.component';
import { SportsComponent } from 'src/app/screens/cells/sports/sports.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { AntiDiscriminationComponent } from 'src/app/screens/cells/anti-discrimination/anti-discrimination.component';
import { GenderComponent } from 'src/app/screens/cells/gender/gender.component';



@NgModule({
  declarations: [
    CellsComponent,
    AdmissionComponent,
    AntiRaggingComponent,
    AntiSquadComponent,
    DisciplineComponent,
    GrievanceComponent,
    InnovationComponent,
    IndustryComponent,
    IccComponent,
    IqacComponent,
    MediaComponent,
    ResearchComponent,
    HarrasmentComponent,
    SportsComponent,
    CounselorComponent,
    AntiDiscriminationComponent,
    GenderComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild([
      {
        path: '', component: CellsComponent, children: [
          { path: 'admission', component: AdmissionComponent },
          { path: 'anti-ragging', component: AntiRaggingComponent },
          { path: 'anti-squad', component: AntiSquadComponent },
          { path: 'discipline', component: DisciplineComponent },
          { path: 'grievance', component: GrievanceComponent },
          { path: 'innovation', component: InnovationComponent },
          { path: 'industry', component: IndustryComponent },
          { path: 'icc', component: IccComponent },
          { path: 'iqac', component: IqacComponent },
          { path: 'media', component: MediaComponent },
          { path: 'research', component: ResearchComponent },
          { path: 'harrasment', component: HarrasmentComponent },
          { path: 'sports', component: SportsComponent },
          { path: 'counselor', component: CounselorComponent },
          { path: 'gender', component: GenderComponent },
          { path: 'anti-discrimination', component: AntiDiscriminationComponent },
        ]
      }
    ])
  ]
})
export class CellsModule { }
