import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-internet',
  templateUrl: './internet.component.html',
  styleUrls: ['./internet.component.scss']
})
export class InternetComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
