<app-banner></app-banner>

<div id="side-navigation">
    <div class="container-fluid mt-4">
        <div class="row">
            <div class="col-12 col-md-4 col-lg-3 side-menu ps-lg-5">
                <h6>Administration</h6>
                <ul class="nav flex-column">
                    <li class="nav-item" routerLinkActive="active">
                        <a class="nav-link" routerLink="/administration">Administration</a>
                    </li>
                </ul>
            </div>
            <div class="col-md-9">
                <div id="administration">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-12">
                                <h4 class="card-title">Administration</h4>
                                <h4>Management</h4>
                                <p class="inner-lead">The college is run by the management of “The Sonipat Hindu Educational &
                                    Charitable Society” SONIPAT.</p>
                
                                <h4>Principal</h4>
                                <p class="inner-lead">Dr. Arun Mittal, M. Pharm, Ph.D. </p>
                
                                <h4>Faculty</h4>
                                <app-faculty-item [staff]="faculty"></app-faculty-item>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>