<div id="counter">
    <div class="container">
        <div class="row about-footer py-4">
            <!-- <h2 class="home-title text-center"></h2> -->
            <div class="col-12 col-md-6 col-lg-4">
                <div class="card text-center">
                    <div class="card-body">
                        <h4 class="text-white"><i class="fa-solid fa-graduation-cap"></i> 10000+</h4>
                        <p class="text-white">Students</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <div class="card text-center">
                    <div class="card-body">
                        <h4 class="text-white"><i class="fa-solid fa-book"></i> 3+</h4>
                        <p class="text-white">Courses</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <div class="card text-center">
                    <div class="card-body">
                        <h4 class="text-white"><i class="fa-solid fa-users"></i> 30+</h4>
                        <p class="text-white">Staff</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>