<div id="about">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <h4 class="card-title">President's Message</h4>
                <div class="text-center"><img src="/assets/img/message/president.jpg" class="img-fluid py-4 inner-img mb-4"
                        alt="President"></div>
                <p class="inner-lead">On behalf of the Hindu College of Education, I extend a warm welcome to all of
                    you. Our institution holds a high reputation and is committed to developing individuals who can
                    excel in their profession while upholding strong moral and ethical standards. We firmly believe that
                    education is the key to a nation’s progress, and it is our responsibility to provide the best
                    academic training to prospective teachers. We emphasize not only on academic excellence but also on
                    character formation, aiming to transform individuals into rational thinkers and competent
                    professionals.</p>
                <p class="inner-lead">We are committed to fostering an environment that encourages personal growth and
                    intellectual development. By providing excellent academic training and promoting high ethical
                    standards, we aim to cultivate future educators who will contribute to the progress of our nation.
                    Together, let us embark on this journey of education and make a positive impact on society.</p>
                <p class="inner-lead fw-bold">Rajiv Aggarwal </p>
                <p class="inner-lead fw-bold">President</p>
                <p class="inner-lead fw-bold">The Sonepat Hindu Educational & Charitable Society</p>
            </div>
        </div>
    </div>
</div>