<div id="admission">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <h4 class="card-title">Fee Detail</h4>
                <div class="table-row">
                    <table class="table table-hover table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">S.No.</th>
                                <th scope="col">Programme</th>
                                <th scope="col">Download</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">1</th>
                                <td>B. Pharm.</td>
                                <td class="text-center"><a href="/assets/pdf/B.Pharmacy-Fee-2021-22.pdf"
                                        target="_blank"><i class="fa-solid fa-eye"></i></a></td>
                            </tr>
                            <tr>
                                <th scope="row">2</th>
                                <td>D. Pharm.</td>
                                <td class="text-center"><a href="/assets/pdf/D.Pharmacy-Fee-2021-22.pdf"
                                        target="_blank"><i class="fa-solid fa-eye"></i></a></td>
                            </tr>
                            <tr>
                                <th scope="row">3</th>
                                <td>M. Pharm.</td>
                                <td class="text-center"><a href="/assets/pdf/M.Pharmacy-Fee-2021-22.pdf"
                                        target="_blank"><i class="fa-solid fa-eye"></i></a></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>