<div id="about">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <h4 class="card-title">Objective</h4>
                <p class="inner-lead">Academic excellence, holistic education, global relevance and contemporarily are
                    the four cornerstones of HCP. Keeping all these in mind, HCP looks at:</p>
                <ul>
                    <li>
                        <p class="inner-lead">Commitment to offer quality education.</p>
                    </li>
                    <li>
                        <p class="inner-lead">Challenge & motivate students to explore their full potential.</p>
                    </li>
                    <li>
                        <p class="inner-lead">Encourage extra-curricular activities among students& develop their
                            overall personality.</p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>