import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.scss']
})
export class CourseComponent implements OnInit {
  course: { degree: string, course: string, desc: string, criteria: string }[] = [
    {
      degree: 'Diploma Programme',
      course: 'D. Pharm.',
      desc: '	This course Diploma of Pharmacy is a 2 year full time yearly course approved by State Board Of Techinal Education, Panchkula Haryana .',
      criteria: 'Through merit prepared by Haryana State Counseling Society on the basis of Senior Secondary marks. For 25% Management quota seats, admission are made through merit prepared on the basis of Qualifying Exam.'
    },
    {
      degree: 'UG Programme',
      course: 'B. Pharm.',
      desc: '	This course Bachelor of Pharmacy is a 4 year full time semester course (8 semesters) approved by .Pt. Bhagwat Dayal Sharma University of Health Sciences, Rohtak.',
      criteria: 'As laid down by Pt. Bhagwat Dayal Sharma University of Health Sciences, Rohtak.'
    },
    {
      degree: 'UG Programme',
      course: 'B. Pharm.(LEET)',
      desc: 'This course Bachelor of Pharmacy is a 3 year full time semester course (6 semesters) approved by .Pt. Bhagwat Dayal Sharma University of Health Sciences, Rohtak.',
      criteria: 'As laid down by Pt. Bhagwat Dayal Sharma University of Health Sciences, Rohtak.'
    },
    {
      degree: 'PG Programme',
      course: 'M. Pharm.  Pharmaceutics',
      desc: 'This course Master of Pharmacy is a 2 year full time semester course (4 semesters) approved by Pt. Bhagwat Dayal Sharma University of Health Sciences, Rohtak.',
      criteria: 'Through merit prepared on the basis of marks obtained in qualifying examination as laid down by Pt. Bhagwat Dayal Sharma University of Health Sciences, Rohtak. Preference is given to GPAT qualified candidates.'
    },
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
