import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SportsComponent } from 'src/app/screens/infrastructure/sports/sports.component';
import { AnimalComponent } from 'src/app/screens/infrastructure/animal/animal.component';
import { CafeteriaComponent } from 'src/app/screens/infrastructure/cafeteria/cafeteria.component';
import { ComputerComponent } from 'src/app/screens/infrastructure/computer/computer.component';
import { ConferenceComponent } from 'src/app/screens/infrastructure/conference/conference.component';
import { GardenComponent } from 'src/app/screens/infrastructure/garden/garden.component';
import { HostelComponent } from 'src/app/screens/infrastructure/hostel/hostel.component';
import { InfrastructureComponent } from 'src/app/screens/infrastructure/infrastructure.component';
import { InternetComponent } from 'src/app/screens/infrastructure/internet/internet.component';
import { LabsComponent } from 'src/app/screens/infrastructure/labs/labs.component';
import { LibraryComponent } from 'src/app/screens/infrastructure/library/library.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [
    InfrastructureComponent,
    ComputerComponent,
    LabsComponent,
    LibraryComponent,
    GardenComponent,
    ConferenceComponent,
    HostelComponent,
    CafeteriaComponent,
    InternetComponent,
    SportsComponent,
    AnimalComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild([
      {
        path: '', component: InfrastructureComponent, children: [
          { path: 'computer', component: ComputerComponent },
          { path: 'labs', component: LabsComponent },
          { path: 'library', component: LibraryComponent },
          { path: 'garden', component: GardenComponent },
          { path: 'conference', component: ConferenceComponent },
          { path: 'hostel', component: HostelComponent },
          { path: 'cafeteria', component: CafeteriaComponent },
          { path: 'internet', component: InternetComponent },
          { path: 'sports', component: SportsComponent },
          { path: 'animal', component: AnimalComponent },
        ]
      }
    ])
  ]
})
export class InfraModule { }
