import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-mission',
  templateUrl: './mission.component.html',
  styleUrls: ['./mission.component.scss']
})
export class MissionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $('#vision-btn').addClass('active');
    $('#vision').collapse('show');
  }

  showCard(id: number) {
    if (id === 1) {
      $('#vision').collapse('show');
      $('#mission').collapse('hide');
      $('#objective').collapse('hide');

      $('#vision-btn').addClass('active');
      $('#mission-btn').removeClass('active');
      $('#objective-btn').removeClass('active');
    }
    if (id === 2) {
      $('#vision').collapse('hide');
      $('#mission').collapse('show');
      $('#objective').collapse('hide');

      $('#vision-btn').removeClass('active');
      $('#mission-btn').addClass('active');
      $('#objective-btn').removeClass('active');
    }
    if (id === 3) {
      $('#vision').collapse('hide');
      $('#mission').collapse('hide');
      $('#objective').collapse('show');

      $('#vision-btn').removeClass('active');
      $('#mission-btn').removeClass('active');
      $('#objective-btn').addClass('active');
    }
  }

}
