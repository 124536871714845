<div class="row">
    <div class="col-12 col-md-6 col-lg-4" *ngFor="let item of staff">
        <div class="our-team">
            <div class="picture">
                <img class="img-fluid" [src]="item.img" [alt]="item.name">
            </div>
            <div class="team-content">
                <h3 class="name">{{item.name}}</h3>
                <h4 class="title">{{item.qual}}</h4>
            </div>
            <h5>{{item.designation}}</h5>
            <ul class="social">
                <li><a [href]="item.resume" target="_blank">{{item.designation}}</a></li>
            </ul>
        </div>
    </div>
</div>