<div id="about">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <h4 class="card-title">Principal’s Message</h4>
                <div class="card-body ">
                    <div class="text-center"><img src="/assets/img/message/principal.jpg" class="img-fluid py-4 inner-img mb-4"
                            alt="Principal"></div>
                    <p class="inner-lead">Welcome to the Website of Hindu College of Pharmacy which was established in
                        1978 by The Sonepat Hindu Educational & Charitable Society with the vision to provide quality
                        pharmacy education to students. During the past forty years the college has made significant
                        contributions towards building professional skills of pharmacy focusing on community pharmacy,
                        hospital pharmacy, clinical pharmacy & industrial pharmacy. Rapid progress have been made by the
                        college in achieving its mission to strengthen, sustain and professionalize pharmacy knowledge
                        through creative research, teaching and learning by integrating with organizations, institutions
                        and universities. It is a matter of pride for all of us that the Hindu College of Pharmacy is
                        being constantly ranked as one of the top pharmacy colleges of the country. It is reflected by
                        the excellent placement record of the college.</p>
                    <p class="inner-lead">Hindu College of Pharmacy stands apart from other institutions on many counts.
                        It is the only College of its kind which provides Pharmacy Education with in-depth focus, along
                        with development of personality and communication skills. To make the students competitive and
                        at par with their counterparts, we have well-equipped laboratories and excellent infrastructure.
                        Pharmacy has expanded immensely and there are a lot of opportunities in Clinical Research,
                        Pharmacovigilance, Nanotechnology, Drug Discovery, Molecular Biology, Phytochemistry and so on.
                        An understanding of these developments and capacity to analyze & respond to such changes are
                        some of the capabilities that are inculcated in the students.</p>
                    <p class="inner-lead">As we strive for excellence in what we do, the college is constantly making
                        endeavors to scale new heights by developing synergy between graduate studies, research programs
                        & pharmaceutical industry. Such a holistic approach would contribute towards continuous growth
                        of the college.</p>
                    <p class="inner-lead fw-bold">Prof. (Dr.) Arun Mittal </p>
                    <p class="inner-lead fw-bold">Principal</p>
                    <p class="inner-lead fw-bold">Hindu College of Pharmacy, Sonepat Haryana</p>
                </div>
            </div>
        </div>
    </div>
</div>