<div id="infrastructure">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                    <h4 class="card-title">Computer Lab</h4>
                        <div class="row text-center">
                            <div class="col-12 col-md-6 mb-3"><img src="/assets/img/infra/comp1.jpg" class="img-fluid rounded" alt=""></div>
                            <div class="col-12 col-md-6 mb-3"><img src="/assets/img/infra/comp2.jpg" class="img-fluid rounded" alt=""></div>
                        </div>
                        <p class="inner-lead">The system of teaching-learning in all the departments are backed by computer lab designed especially to cater the needs of students. It is built in strict accordance with AICTE specification. The lab provided to the students, facilitate the access to information highways through internet.</p>
                        <p class="inner-lead">The computer lab is air conditioned with latest hardware and software utilities. The lab has printer which comprises of Laser Inkjet & Dot Matrix Printers.</p>
                        <p class="inner-lead">Number and Configuration of Systems: P4 – 50</p>
                        <p class="inner-lead">Total number of systems connected by LAN: 50</p>
                        <p class="inner-lead">Internet bandwidth: Broadband 16MBPS</p>
                        <p class="inner-lead">Major software packages available: MS Window 2007, MS Office 2007, Guardian Antivirus, C Language.</p>
                        <p class="inner-lead">Special purpose facilities available: Library software, Pharmacology Software.</p>
                    </div>
                </div>
            </div>
        </div>