<!-- <div id="why" class="py-4">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="whyImage">
                    <div class="box"></div>
                    <div class="image">
                        <img src="/assets/img/home-about/0.jpg" alt="" class="img-fluid">
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 my-auto ps-5">
                <h1 class="home-title"><span>Why Hindu College Of Pharmacy?</span></h1>
                <p><b>Global Citizenship:</b> Equipping students with the knowledge and skills to excel in an ever-evolving
                    global arena, enabling them to navigate and thrive in unknown territories of the future.
                </p>
                <p><b>Ethical Leadership:</b> Encouraging students to lead with integrity, empathy, and a deep sense of social
                    responsibility towards humanity and the environment.</p>
                <p><b>Sustainability Focus:</b> Instilling values of environmental stewardship, inspiring students to become
                    advocates for sustainable living and positive change in their communities.</p>
                <p><b>Holistic Development:</b> Nurturing well-rounded individuals by balancing academic excellence with
                    emotional intelligence, compassion, and respect for all living beings.</p>
            </div>
        </div>
    </div>
</div> -->

<div id="why">
    <div class="container pt-3 pb-5">
        <div class="row">
            <div class="col-12 text-center">
                <h2 class="home-title">WHAT MAKES HCP UNIQUE?</h2>
                <p>We equip our students with the knowledge and skills needed to excel in the ever-evolving professional landscape.</p>
            </div>
            <div class="col-12 col-lg-6 mb-4">
                <div class="box box1">
                    <img src="/assets/img/infra/lib2.jpg" alt="" class="img-fluid rounded-3">
                    <div class="overlay2 p-4 text-white">
                        <h5 class="mb-0">Legacy of</h5>
                        <h3> Visionary Education</h3>
                        <p class="d-none d-md-block d-lg-none d-xl-block">HCP traces its roots back to 1938,with its rich
                            heritage and strong foundation.</p>
                    </div>
                </div>
                <div class="mt-4 box box2">
                    <img src="/assets/img/infra/comp1.jpg" alt="" class="img-fluid rounded-3">
                    <div class="overlay1 p-4 text-white">
                        <h5 class="mb-0">Diverse and Future</h5>
                        <h3> Oriented Programs</h3>
                        <p class="d-none d-md-block d-lg-none d-xl-block">At HCP, we believe in staying ahead of
                            the curve by constantly striving to upgrade our
                            curriculum.</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div class="box box3">
                    <img src="/assets/img/infra/lab1.jpg" alt="" class="img-fluid rounded-3">
                    <div class="overlay3 p-4 text-white">
                        <h5 class="mb-0">State-of-the-Art  Facilities</h5>
                        <h3> and Holistic Development</h3>
                        <p class="d-none d-md-block d-lg-none d-xl-block">We prioritize the overall
                            development of our students, and our students
                            have consistently brought accolades to the college.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>