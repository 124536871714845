<div id="admission">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <h4 class="card-title">Course Offered</h4>
                <div class="table-row">
                    <table class="table table-hover table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">Diploma/Degree</th>
                                <th scope="col">Course Name </th>
                                <th scope="col">Description</th>
                                <th scope="col">Eligibility Criteria</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of course">
                                <th scope="row">{{ item.degree }}</th>
                                <th scope="row">{{ item.course }}</th>
                                <td>{{ item.desc }}</td>
                                <td>{{ item.criteria }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>