<app-banner></app-banner>

<div id="side-navigation">
    <div class="container-fluid mt-4">
        <div class="row">
            <div class="col-12 col-md-4 col-lg-3 side-menu ps-lg-5">
                <h6>About Us</h6>
                <ul class="nav flex-column">
                    <li class="nav-item" routerLinkActive="active">
                        <a class="nav-link" routerLink="/about/history">History</a>
                    </li>
                    <li class="nav-item" routerLinkActive="active">
                        <a class="nav-link" routerLink="/about/president">President's
                            Message</a>
                    </li>
                    <li class="nav-item" routerLinkActive="active">
                        <a class="nav-link" routerLink="/about/principal">Principal's
                            Message</a>
                    </li>
                    <li class="nav-item" routerLinkActive="active">
                        <a class="nav-link" routerLink="/about/objective">Objective</a>
                    </li>
                    <li class="nav-item" routerLinkActive="active">
                        <a class="nav-link" routerLink="/about/affiliation">Affiliation and Approval</a>
                    </li>
                    <li class="nav-item" routerLinkActive="active">
                        <a class="nav-link" routerLink="/about/governing">Governing Body</a>
                    </li>
                </ul>
            </div>
            <div class="col-md-9">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>