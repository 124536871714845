import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-teaching',
  templateUrl: './teaching.component.html',
  styleUrls: ['./teaching.component.scss']
})
export class TeachingComponent implements OnInit {

  facultyList = [
    {
      name: 'Prof. (Dr.) Dinesh Kaushik',
      designation: 'Professor',
      qualification: 'M. Pharm, PhD',
      experience: '27 Years',
      email: 'dinesh.k.hcp@gmail.com',
      image: '/assets/img/faculty/2.jpg',
      autobiography: 'https://drive.google.com/file/d/1lUXYkdAH04rmHM_3skklD2vzkPp6z87m/view?usp=drive_link'
    },
    {
      name: 'Dr. Bharat Bhushan',
      designation: 'Associate Professor & HOD',
      qualification: 'M.Pharmacy, PhD',
      experience: '25 Years',
      email: '',
      image: 'assets/img/autobiography/1.jpg',
      autobiography: 'https://drive.google.com/file/d/1C0Qmwm2FzD0vFaS_7w4MMZi_-5SeZc-N/view?usp=drive_link'
    },
    {
      name: 'Ms. Swati Saini',
      designation: 'Assistant Professor',
      qualification: 'M. Pharmacy, Ph.D. (Pursuing)',
      experience: '2 Years',
      email: 'swati.s.hcp@gmail.com',
      image: 'assets/img/autobiography/2.png',
      autobiography: 'https://drive.google.com/file/d/1yr4m4GOc8ZJLkcUDmsDvm8NhfUo0nGaI/view?usp=drive_link'
    },
    {
      name: 'Ms. Ruchi Bansal',
      designation: 'Assistant Professor',
      qualification: 'M. Pharma (Pharmaceutics)',
      experience: '1 Year 4 Months' ,
      email: 'ruchi.b.hcp@gmail.com',
      image: 'assets/img/autobiography/3.jpg',
      autobiography: 'https://drive.google.com/file/d/1pMikoELlaDedJwM3dVJsFLS9fJhKVjbA/view?usp=drive_link'
    },
    {
      name: 'Dr. Jyoti',
      designation: 'Assistant Professor',
      qualification: 'M. Pharm., MBA, Ph.D., PGDRA',
      experience: '14 Years',
      email: 'jyoti.a.hcp@gmail.com',
      image: 'assets/img/autobiography/4.jpg',
      autobiography: 'https://drive.google.com/file/d/1IYBIJlVJKCLDTZZmtz145ql3hxWZ2Zel/view?usp=drive_link'
    },
    {
      name: 'Dr. Preeti Garg',
      designation: 'Assistant Professor',
      qualification: 'M. Pharm. Ph.D',
      experience: '12 Years 5 Months',
      email: 'priti.g.hcp@gmail.com',
      image: 'assets/img/autobiography/5.png',
      autobiography: 'https://drive.google.com/file/d/1-d5wA9InwivcEwPxqQtF2DoWiYB76WRQ/view?usp=drive_link'
    },
    {
      name: 'Mrs. Shilpa jain',
      designation: 'Assistant Professor',
      qualification: 'M. Pharm., Ph.D.',
      experience: '11 Years',
      email: 'Shilpa.j.hcp@gmail.com',
      image: 'assets/img/autobiography/6.png',
      autobiography: 'https://drive.google.com/file/d/1AueMB--I9eCx4pB_Qc00tOzLfxwwbRBB/view?usp=drive_link'
    },
    {
      name: 'Ms. ITTISHREE',
      designation: 'Assistant Professor',
      qualification: 'M. Pharm. Ph.D. (Pursuing)',
      experience: '6 Years',
      email: 'ittishree.b.hcp@gmail.com',
      image: 'assets/img/autobiography/7.png',
      autobiography: 'https://drive.google.com/file/d/15ugxsEeY2z8a86HlYdXkEfj5lQAZIFLp/view?usp=drive_link'
    },
    {
      name: 'Mr. Ajesh Chauhan',
      designation: 'Assistant Professor',
      qualification: 'B.Pharm, M.Pharm, PhD (Pru.)',
      experience: '5 Years',
      email: 'ajesh.c.hcp@gmail.com',
      image: 'assets/img/autobiography/8.png',
      autobiography: 'https://drive.google.com/file/d/1eaYFNSZ7vwQc-_2qcN3qaI8LOgFhMltr/view?usp=drive_link'
    },
    {
      name: 'Mrs. Pooja',
      designation: 'Assistant Professor',
      qualification: 'M. Pharm. Ph.D (Pur.)',
      experience: '5 Years',
      email: '',
      image: 'assets/img/autobiography/9.png',
      autobiography: 'https://drive.google.com/file/d/1klxEyvKPCKaX0NQT-aaa5d8x2QWnCTNw/view?usp=drive_link'
    },
    {
      name: 'Mr. Birender Singh',
      designation: 'Assistant Professor',
      qualification: 'M. Pharm. Ph.D. (Pursuing)',
      experience: '12 Years',
      email: 'birender.s.hcp@gmail.com',
      image: 'assets/img/autobiography/10.png',
      autobiography: 'https://drive.google.com/file/d/1a4srzRb55ThBdfubx_QkL-WeDnqpXFnY/view?usp=drive_link'
    },
    {
      name: 'Mr. Naveen',
      designation: 'Assistant Professor',
      qualification: 'M. Pharm. Ph.D. (Pursuing)',
      experience: '8 Years',
      email: 'naveen.g.hcp@gmail.com ',
      image: 'assets/img/autobiography/11.png',
      autobiography: 'https://drive.google.com/file/d/1dr5mAFnxVKuBuerMWj-ZydMWGpv1mTNo/view?usp=drive_link'
    },
    {
      name: 'Mr. SANGEET KUMAR SHARMA ',
      designation: 'Assistant Professor',
      qualification: 'M. Pharm. Ph.D. (Pursuing)',
      experience: '11 Years',
      email: 'sangeet.s.hcp@gmail.com',
      image: 'assets/img/autobiography/12.png',
      autobiography: 'https://drive.google.com/file/d/1jkiRh2POeA5NarAZP3cVi1hjZh-VqSOq/view?usp=drive_link'
    },
    {
      name: 'Ms. Shivani',
      designation: 'Assistant Professor',
      qualification: 'M. Pharm. Ph.D. (Pursuing)',
      experience: '2 Years',
      email: 'shivani906sharma@gmail.com',
      image: 'assets/img/autobiography/13.png',
      autobiography: 'https://drive.google.com/file/d/10oq9vwBXsSD_8J9iCkSB9V9eFjlQhZ3x/view?usp=drive_link'
    },
    {
      name: 'Mr. Vineet Vashishth',
      designation: 'Assistant Professor',
      qualification: 'M. Pharm. (Pharmaceutics)',
      experience: '7 Years',
      email: ' Vineet.v.hcp@gmail.com',
      image: 'assets/img/autobiography/14.png',
      autobiography: 'https://drive.google.com/file/d/1hRPntrqun-jnq3DFl908my5qSSFKUuds/view?usp=drive_link'
    },
    {
      name: 'Ms. Priyanka Sharma ',
      designation: 'Assistant Professor',
      qualification: 'M. Pharm. Ph.D. (Pursuing)',
      experience: '',
      email: 'priyankka.s.hcp@gmail.com ',
      image: 'assets/img/autobiography/15.png',
      autobiography: 'https://drive.google.com/file/d/1A2IKqOmb2TuxHwz5Hs3XVVxSY2F2_FFp/view?usp=drive_link'
    },
    {
      name: 'Ms. Jyoti',
      designation: 'Assistant Professor',
      qualification: 'M. Pharm. Ph.D. (Pursuing)',
      experience: '4 Years 8 Months',
      email: 'jyoti.j.hcp@gmail.com',
      image: 'assets/img/autobiography/16.png',
      autobiography: 'https://drive.google.com/file/d/1ILrkLrjxJs0VfontMDYBcshWPRaRXzD_/view?usp=drive_link'
    },
    {
      name: 'Mr. VISHNU DUTT',
      designation: 'Assistant Professor',
      qualification: 'MCA, MBA',
      experience: '',
      email: ' vishnudutt.b.hcp@gmail.com ',
      image: 'assets/img/autobiography/17.png',
      autobiography: 'https://drive.google.com/file/d/1uR6d4fKREEJ_-o1dk3y4-9_NapldjKpA/view?usp=drive_link'
    },
    {
      name: 'Mrs. Geetanjali Dahiya',
      designation: '',
      qualification: 'M.Pharmacy, PhD.(Pursuing),PG Diploma in Clinical Trials & Pharmacovigilance',
      experience: '',
      email: '',
      image: 'assets/img/autobiography/18.png',
      autobiography: 'https://drive.google.com/file/d/1ghsD8AFHXAJaPfERfKtx4XctXFJSSbgu/view?usp=drive_link'
    },
    {
      name: 'Ms. Sonam Rani',
      designation: 'Assistant Professor',
      qualification: 'M. Pharm.',
      experience: '3 Years 6 Months',
      email: 'sonam.j.hcp@gmail.com',
      image: 'assets/img/autobiography/19.png',
      autobiography: 'https://drive.google.com/file/d/1-EzYAGyjUDwkTo67tYWoJ9lxzWaYvJth/view?usp=drive_link'
    },
    {
      name: 'Mrs. Reetu Rani',
      designation: 'Assistant Professor',
      qualification: 'M. Pharma (Pharmaceutics)',
      experience: '5 Years',
      email: 'reetu.r.hcp@gmail.com',
      image: 'assets/img/autobiography/20.png',
      autobiography: '#'
    },
    {
      name: 'Mrs. Anju Rani',
      designation: 'Lecturer',
      qualification: 'B.Pharm',
      experience: '6 Years 6 Months',
      email: 'anju.rani.hcp@gmail.com',
      image: '/assets/img/faculty/21.jpg',
      autobiography: 'https://drive.google.com/file/d/1RWDi84Xu1PaLwJQc5YyoeZ2Wk1vhzlSY/view?usp=drive_link'
    },
    
    
    
  ];
  constructor() { }

  ngOnInit(): void {
  }

}
