<div id="about-us" class="py-4">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-md-7">
                <img src="/assets/img/home-about/0.jpg" class="img-fluid pe-md-5" alt="Building">
            </div>
            <div class="col-12 col-md-5 my-auto">
                <h1 class="home-title"><span>About</span> The College</h1>
                <p>Established in 1978, <b>Hindu College of Pharmacy</b> is one of the premier institutions in Haryana,
                    dedicated to providing top-notch pharmaceutical education. The college operates under the esteemed
                    <b>The Sonepat Hindu Educational and Charitable Society</b>, which was established in 1914 with the
                    mission
                    of delivering quality education and contributing to societal welfare.
                </p>
                <p>The college is approved by the <b>Pharmacy Council of India (PCI), New Delhi</b>, and is affiliated
                    with
                    both <b>Pt. Bhagwat Dayal Sharma University of Health Sciences, Rohtak, Haryana</b>, and the
                    <b>Haryana State Board of Technical Education (HSBTE), Panchkula, Haryana.</b>
                </p>
                <a href="">About The College <i class="fa-solid fa-circle-chevron-right ms-2"></i></a>
            </div>
        </div>
    </div>
</div>