<div id="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6 col-lg-3">
                <div class="widget dark">
                    <div class="footer-logo">
                        <img class="mt-3 img-fluid rounded-circle mb-3" alt="Logo" src="/assets/img/logo/3.png">
                    </div>
                  
                  <h4 class=" text-center mb-0">HINDU COLLEGE OF PHARMACY</h4>
                  <p class="text-center">Near Panchayat Bhawan, Gohana Road, Sonipat,, Haryana 131001</p>
                  <ul class="list-inline  text-center">
                    <li class="m-0 ps-10 pe-10"> <i class="fa-solid fa-phone me-2"></i> <a  href="tel:0130-2221072">0130-2221072</a> </li>
                    <li class="m-0 ps-10 pe-10"> <i class="fa-solid fa-envelope  me-2"></i> <a class="spamspan" href="mailto:hinduglobal.snp@gmail.com">hindupharmacycolllege@gmail.com</a> </li>
                    <li class="m-0 ps-10 pe-10"> <i class="fa-solid fa-link me-2"></i> <a  href="www.hcpsonepat.org" target="_blank">www.hcpsonepat.org</a> </li>
                  </ul>
                  <ul class="social clearfix mt-10 text-center">
                    <li><a href="" target="_blank" aria-label="Twitter"><i class="fa-brands fa-twitter"></i></a></li>
                    <li><a href="" target="_blank" aria-label="Instagram"><i class="fa-brands fa-instagram"></i></a></li>
                    <li><a href="" target="_blank" aria-label="Facebook"><i class="fa-brands fa-facebook-f"></i></a></li> 
                    <li><a href="" target="_blank" aria-label="Youtube"><i class="fa-brands fa-youtube"></i></a></li>
                            
                  </ul>
                </div>
              </div>
            <div class="col-md-6 col-lg-3">
                <div class="link">
                    <h4 class="">Quick Link</h4>
                    <hr class="mb-0  opacity-100">
                    <ul class="ps-0">
                        <li><a routerLink="/administration" >Administration</a></li>
                        <li><a href="/assets/pdf/Admission Form.pdf" target="_blank">Admission Form</a></li>
                        <li><a routerLink="/alumni" >Alumni</a></li>
                        <li><a routerLink="/admission/fee" >Fee Detail</a></li>
                        <li><a routerLink="/contact-us" >Contact Us</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-md-6 col-lg-3">
                <div class="link borderRight">
                    <h4 class="">Explore</h4>
                    <hr class="mb-0  opacity-100">
                    <ul class="ps-0">
                        <li><a routerLink="/registration">Apply Now</a></li>
                        <li><a routerLink="/achievement">Achievement</a></li>
                        <li><a routerLink="/admission/course">Course Offered</a></li>
                        <li><a routerLink="/cells/anti-ragging">Anti Ragging Cell</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-md-6 col-lg-3">
               <app-map></app-map>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-12 text-right">
                <a href="https://okiedokiepay.com/" class="design" target="_blank">Crafted with ❤️ by Okie Dokie <br>
                    Haryana's No. 1 Campus Automation Partner</a>
            </div>
        </div>
    </div>
</div>