<div id="about">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <h4 class="card-title">VISION & MISSION</h4>
                <p class="inner-lead text-center"><i>“STRONG WORK ETHICS AND PERSERVERANCE TO ACHIEVE GOAL”</i></p>
                <ul>
                    <li>
                        <p class="inner-lead">To develop professionals who are committed to their personal &
                            professional endeavors and who have the vision, courage & dedication to initiate and manage
                            changes.</p>
                    </li>
                    <li>
                        <p class="inner-lead">To serve as an intellectual resources based in India, maintain the
                            standard of excellence in every aspect of operation, and become a role model for newly
                            emerging quality education.</p>
                    </li>
                    <li>
                        <p class="inner-lead">To provide innovative, effective snd meaningful education to the students
                            translating them into a cadre of professionals working globally with a heightened sense of
                            morality and social responsibility.</p>
                    </li>
                </ul>
                <p class="inner-lead">To raise and foster our students by synergizing knowledge and technical training
                    to convert them into future healthcare professionals.</p>
            </div>
        </div>
    </div>
</div>