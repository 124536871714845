<div id="about">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <h4 class="card-title">History</h4>
                <p class="inner-lead">Hindu College of Pharmacy (HCP), one of the pioneers Institute in Pharmacy
                    Education in the Haryana was established in year 1978 by “The Sonepat Hindu Educational and
                    Charitable Society” with the vision to provide quality pharmacy education to students. It started as
                    Diploma in Pharmacy in Haryana for providing the education under the State Board of Technical
                    Education, Panchkula Haryana. The college is approved by AICTE and PCI new Delhi.</p>
                <p class="inner-lead">Later in the year 1995 the institute started Bachelor of Pharmacy under
                    affiliation from Maharishi Dayanad University, Rohtak and in the year 2007, the institute started
                    Master of Pharmacy programmes. Currently, Bachelor of Pharmacy and Master of Pharmacy
                    (Pharmaceutics) are affiliated to Pt. Bhagwat Dayal Sharma University of Health Sciences, Rohtak.
                </p>
                <p class="inner-lead">The Institute owns about 2.16 acres of land and possess lush green sprawling
                    lawns, sufficient number of classrooms with audio visual aids, air conditioned conference room,
                    faculty Rooms, Director’s Office, Placement office and other facilities such as generator, internet
                    & a good cafeteria for the students.</p>
                <p class="inner-lead">During the past forty years the college has made significant contributions towards
                    building professional skills of pharmacy focusing on community pharmacy, hospital pharmacy, clinical
                    pharmacy & industrial pharmacy. Rapid progress have been made by the college in achieving its
                    mission to strengthen, sustain and professionalize pharmacy knowledge through creative research,
                    teaching and learning by integrating with organizations, institutions and universities. Guest
                    lectures by eminent scientists, national seminars, workshops and industrial visits are conducted at
                    regular intervals to update the knowledge of the students. Faculty members are encouraged to attend
                    UGC/AICTE/PCI sponsored staff development programs, refresher courses and national seminars so as to
                    update themselves with recent trends.</p>
            </div>
        </div>
    </div>
</div>