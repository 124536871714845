<div id="cells">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <h4 class="card-title">Research Cell</h4>
                <div class="text-center"><img src="/assets/img/cells/research.jpg" class="img-fluid rounded-4"
                        alt="Admission Status"></div>
            </div>
        </div>
    </div>
</div>