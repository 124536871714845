<div id="news">
    <div class="container">
        <div class="row pt-4">
            <div class="col-12 col-lg-6 mx-auto">
                <div class="row align-items-center mb-3">
                    <div class="col-12">
                        <h1 class="home-title text-center"><span>Latest</span> News & Events</h1>
                    </div>
                </div>
                <owl-carousel-o [options]="customOptions">
                    <ng-container *ngFor="let slide of news">
                      <ng-template carouselSlide [id]="slide.title">
                        <div class="card newsCard mb-3">
                            <img [src]="slide.imgUrl" [alt]="slide.title" [title]="slide.title" class="img-fluid">
                            <div class="card-body">
                                <h6 class="card-title mt-2"  [routerLink]="['/events',slide.$key]">{{slide.title}}</h6>
                                <div class="d-flex justify-content-between align-items-center">
                                    <span><i class="fa-solid fa-user-graduate"></i> {{slide.category}}</span>
                                    <span class="float-end"><i class="fa-regular fa-clock"></i>
                                        {{slide.date}}</span>
                                </div>
                            </div>
                        </div>
                        
                      </ng-template>
                    </ng-container>
                  </owl-carousel-o>
            </div>
            <div class="col-12 col-lg-6">
                <h1 class="home-title mb-3"><span>Students</span>  And Staff Notices</h1>
                <div class="card newsCard notices py-4">
                    <marquee behavior="smooth" direction="up" onmouseover="this.stop();" onmouseout="this.start();">
                        <div class="row">
                            <div class="col-12 mb-3" *ngFor="let notice of notices;">
                                <div class="card mx-3">
                                    <div >
                                        <div class="row">
                                            <div class="col-3">
                                                <div class="box d-flex justify-content-center flex-column align-items-center py-2">
                                                    <h6>{{notice.day}}</h6>
                                                    <h5>{{notice.month}}</h5>
                                                    <h6>{{notice.year}}</h6>
                                                </div>
                                            </div>
                                            <div class="col-9 py-2">
                                                <a href="{{notice.imgUrl}}" target="_blank">{{notice.title}}</a>
                                                <h4>Notice For: {{notice.noticeFor}}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </marquee>
                </div>
            </div>

        </div>
    </div>
</div>