import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-anti-discrimination',
  templateUrl: './anti-discrimination.component.html',
  styleUrls: ['./anti-discrimination.component.scss']
})
export class AntiDiscriminationComponent implements OnInit {
  members = [
    { srNo: 1, name: 'Prof. (Dr.) Arun Mittal (Principal)', designation: 'Chairperson', mobile: '9354806431', email: 'mittalarun3177@gmail.com' },
    { srNo: 2, name: 'Mr. Naveen (Asst. Prof.)', designation: 'Member', mobile: '8053258323', email: 'naveen.g.hcp@gmail.com' },
    { srNo: 3, name: 'Ms. Swati Saini (Asst. Prof.)', designation: 'Member', mobile: '9813989409', email: 'swati.s.hcp@gmail.com' },
    { srNo: 4, name: 'Ms. Shivani Sharma (Asst. Prof.)', designation: 'Member', mobile: '8178202074', email: 'shivani906sharma@gmail.com' },
    { srNo: 5, name: 'Mr. Jokhan Parsad (Lab. Attendant)', designation: 'Member', mobile: '9315590308', email: 'hindupharmacycollege@gmail.com' },
    { srNo: 6, name: 'Mrs. Ranjana Devi (Lab. Attendant)', designation: 'Member', mobile: '9050154501', email: 'hindupharmacycollege@gmail.com' }
  ];
  constructor() { }

  ngOnInit(): void {
  }

}
