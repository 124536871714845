import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-anti-ragging',
  templateUrl: './anti-ragging.component.html',
  styleUrls: ['./anti-ragging.component.scss']
})
export class AntiRaggingComponent implements OnInit {
  members = [
    { srNo: 1, name: 'Prof. (Dr.) Arun Mittal (Principal)', designation: 'Chairperson', mobile: '9354806431', email: 'mittalarun3177@gmail.com' },
    { srNo: 2, name: 'Dr. Dinesh Kaushik (Professor)', designation: 'Member', mobile: '9812307000', email: 'dinesh.k.hcp@gmail.com' },
    { srNo: 3, name: 'Dr. Bharat Bhushan (Associate Prof.)', designation: 'Member', mobile: '9416179732', email: 'bbhushanpharma@yahoo.com' },
    { srNo: 4, name: 'Dr. Preeti Garg (Asst. Prof.)', designation: 'Member', mobile: '9466274341', email: 'priti.g.hcp@gmail.com' },
    { srNo: 5, name: 'Mrs. Shilpa Jain (Asst. Prof.)', designation: 'Member', mobile: '9354176887', email: 'shilpa.j.hcp@gmail.com' },
    { srNo: 6, name: 'Mrs. Sangeeta Sharma (Librarian)', designation: 'Member', mobile: '8950119980', email: 'sangeetasunilbhardwaj@gmail.com' }
  ];
  constructor() { }

  ngOnInit(): void {
  }

}
