import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './screens/home/home.component';
import { RegistrationComponent } from './screens/registration/registration.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'about', loadChildren: './modules/about/about.module#AboutModule' },
  { path: 'admission', loadChildren: './modules/admission/admission.module#AdmissionModule' },
  { path: 'administration', loadChildren: './modules/administration/administration.module#AdministrationModule' },
  { path: 'cells', loadChildren: './modules/cells/cells.module#CellsModule' },
  { path: 'infra', loadChildren: './modules/infra/infra.module#InfraModule' },
  { path: 'faculty', loadChildren: './modules/faculty/faculty.module#FacultyModule' },
  { path: 'contact-us', loadChildren: './modules/contact/contact.module#ContactModule' },
  { path: 'registration', component: RegistrationComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
