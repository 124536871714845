<app-banner></app-banner>

<div id="side-navigation">
    <div class="container-fluid mt-4">
        <div class="row">
            <div class="col-12 col-md-4 col-lg-3 side-menu ps-lg-5">
                <h6>Admission</h6>
                <ul class="nav flex-column">
                    <li class="nav-item" routerLinkActive="active">
                        <a class="nav-link" href="/assets/pdf/Admission Form.pdf" target="_blank">Admission Form</a>
                    </li>
                    <li class="nav-item" routerLinkActive="active">
                        <a class="nav-link" routerLink="/admission/course">Course
                            Offered</a>
                    </li>
                    <li class="nav-item" routerLinkActive="active">
                        <a class="nav-link" routerLink="/admission/status">Admission
                            Status</a>
                    </li>
                    <li class="nav-item" routerLinkActive="active">
                        <a class="nav-link" routerLink="/admission/fee">Fee
                            Detail</a>
                    </li>
                </ul>
            </div>
            <div class="col-md-9">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>