<app-showcase></app-showcase>
<app-bulletin></app-bulletin>
<app-about-us></app-about-us>
<app-counter></app-counter>
<app-mission></app-mission>
<app-why></app-why>
<app-feature></app-feature>
<app-news></app-news>
<app-home-courses></app-home-courses>
<app-messages></app-messages>
<app-achievement></app-achievement>
<app-infra></app-infra>