import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FacultyComponent } from 'src/app/screens/faculty/faculty.component';
import { TeachingComponent } from 'src/app/screens/faculty/teaching/teaching.component';
import { NonTeachingComponent } from 'src/app/screens/faculty/non-teaching/non-teaching.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';



@NgModule({
  declarations: [
    FacultyComponent,
    TeachingComponent,
    NonTeachingComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
        RouterModule.forChild([
          {
            path: '', component: FacultyComponent, children: [
              { path: 'teaching', component: TeachingComponent },
              { path: 'non-teaching', component: NonTeachingComponent },
  
            ]
          }
        ])
  ]
})
export class FacultyModule { }
