<div id="about">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <h4 class="card-title">Governing body</h4>
                <p class="inner-lead fw-bold">The college is run by “The Sonipat Hindu Educational & Charitable Society”
                    SONIPAT.</p>
            </div>
        </div>
    </div>
</div>