<!-- <div id="showcase">
    <div id="showcaseCarousel" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-indicators">
            <button type="button" data-bs-target="#showcaseCarousel" data-bs-slide-to="0" class="active"
                aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#showcaseCarousel" data-bs-slide-to="1" class="active"
                aria-current="true" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#showcaseCarousel" data-bs-slide-to="2" class="active"
                aria-current="true" aria-label="Slide 3"></button>
            <button type="button" data-bs-target="#showcaseCarousel" data-bs-slide-to="3" class="active"
                aria-current="true" aria-label="Slide 4"></button>
        </div>
        <div class="carousel-inner">
            <div class="carousel-item active">
                <div class="container-fluid d-flex justify-content-center align-items-center" id="loader"
                    *ngIf="isLoading[0]">
                    <app-loader></app-loader>
                </div>
                <img [src]="imgUrl[0]" alt="Slider" class="img-fluid animate__animated animate__slideInRight w-100"
                    [ngStyle]="{visibility:isLoading[0]?'hidden':'visible'}" (load)="hideLoader(0)" style="height: 600px; object-fit: cover;"
                    (error)="hideLoader(0)">
                <div class="carousel-caption d-none d-md-block" *ngIf="!isLoading[0]">
                    <div class="sub-title d-flex align-items-center">
                        <div class="border-box"></div>
                        <h3 class="mb-0">Care &amp; education</h3>
                    </div>
                    <h2 class="text-start">We raise </h2>
                    <h2 class="text-start">leaders </h2>
                    <h2 class="text-start">for the future</h2>
                </div>
            </div>
            <div class="carousel-item">
                <div class="container-fluid d-flex justify-content-center align-items-center" id="loader"
                    *ngIf="isLoading[1]">
                    <app-loader></app-loader>
                </div>
                <img [src]="imgUrl[1]" alt="Slider" class="img-fluid animate__animated animate__slideInRight w-100"
                    [ngStyle]="{visibility:isLoading[1]?'hidden':'visible'}" (load)="hideLoader(1)" style="height: 600px; object-fit: cover;"
                    (error)="hideLoader(1)">
                <div class="carousel-caption d-none d-md-block animate__animated animate__slideInUp" *ngIf="!isLoading[1]">
                    <div class="sub-title d-flex align-items-center">
                        <div class="border-box"></div>
                        <h3 class="mb-0">Care &amp; education</h3>
                    </div>
                    <h2 class="text-start">Learning Today, </h2>
                    <h2 class="text-start">For Leading </h2>
                    <h2 class="text-start">Tomorrow</h2>
                </div>
            </div>
            <div class="carousel-item">
                <div class="container-fluid d-flex justify-content-center align-items-center" id="loader"
                    *ngIf="isLoading[2]">
                    <app-loader></app-loader>
                </div>
                <img [src]="imgUrl[2]" alt="Slider" class="img-fluid animate__animated animate__slideInRight w-100"
                    [ngStyle]="{visibility:isLoading[2]?'hidden':'visible'}" (load)="hideLoader(2)" style="height: 600px; object-fit: cover;"
                    (error)="hideLoader(2)">
                <div class="carousel-caption d-none d-md-block animate__animated animate__slideInUp" *ngIf="!isLoading[2]">
                    <div class="sub-title d-flex align-items-center">
                        <div class="border-box"></div>
                        <h3 class="mb-0">Care &amp; education</h3>
                    </div>
                    <h2 class="text-start">Stop Not, </h2>
                    <h2 class="text-start">Till the goal </h2>
                    <h2 class="text-start">is reached</h2>
                </div>
            </div>
            <div class="carousel-item">
                <div class="container-fluid d-flex justify-content-center align-items-center" id="loader"
                    *ngIf="isLoading[3]">
                    <app-loader></app-loader>
                </div>
                <img [src]="imgUrl[3]" alt="Slider" class="img-fluid animate__animated animate__slideInRight w-100"
                    [ngStyle]="{visibility:isLoading[3]?'hidden':'visible'}" (load)="hideLoader(3)" style="height: 600px; object-fit: cover;"
                    (error)="hideLoader(3)">
                <div class="carousel-caption d-none d-md-block animate__animated animate__slideInUp" *ngIf="!isLoading[3]">
                    <div class="sub-title d-flex align-items-center">
                        <div class="border-box"></div>
                        <h3 class="mb-0">Care &amp; education</h3>
                    </div>
                    <h2 class="text-start">Vision </h2>
                    <h2 class="text-start">is </h2>
                    <h2 class="text-start">everything</h2>
                </div>
            </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#showcaseCarousel" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#showcaseCarousel" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button>
    </div>
</div> -->

<div id="showcase">
    <div id="showcaseCarousel" class="carousel slide" data-bs-ride="carousel">
        <!-- <div class="carousel-indicators">
            <button type="button" data-bs-target="#showcaseCarousel" *ngFor="let slide of slider, let i = index"
                class="{{ (i == 0) ? 'active' : '' }}" data-bs-slide-to="0" aria-current="true" aria-label="Slide 1"
                attr.data-bs-slide-to="{{i}}"></button>
        </div> -->
        <div class="carousel-inner">
            <div class="carousel-item" *ngFor="let slide of slider, let i=index" [ngClass]="(i == 0 ) ? 'active' : ''">
                <div class="container-fluid d-flex justify-content-center align-items-center" id="loader"
                    *ngIf="isLoading[i]">
                    <app-loader></app-loader>
                </div>
                <img [src]="slide.imgUrl" alt="Slider" class="img-fluid animate__animated animate__slideInRight w-100"
                    [ngStyle]="{visibility:isLoading[i]?'hidden':'visible'}" (load)="hideLoader(i)"
                    (error)="hideLoader(i)">
                <div class="carousel-caption d-none d-md-block" *ngIf="!isLoading[i]">
                    <h1>{{slide.title}}</h1>
                </div>
            </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#showcaseCarousel" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#showcaseCarousel" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button>
    </div>
</div>