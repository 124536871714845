<div id="achievement" class="mt-4 mb-5">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="card shadow">
                    <h2 class="home-title text-center">Alumni</h2>

                    <div class="row mt-4">
                        <div class="col-12 col-md-4 mb-3">
                            <div class="card achievementCard p-0 shadow">
                                <div class="image">
                                    <img src="/assets/img/achievement/1.jpg" class="img-fluid w-100" alt="Achievement">
                                </div>
                                <div class="card-body">
                                    <h3>SOAR - New Student Orientation</h3>
                                    <p>Student Orientation and Registration (SOAR) | On Campus</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-4 mb-3">
                            <div class="card achievementCard p-0 shadow">
                                <div class="image">
                                    <img src="/assets/img/achievement/1.jpg" class="img-fluid w-100" alt="Achievement">
                                </div>
                                <div class="card-body">
                                    <h3>SOAR - New Student Orientation</h3>
                                    <p>Student Orientation and Registration (SOAR) | On Campus</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-4 mb-3">
                            <div class="card achievementCard p-0 shadow">
                                <div class="image">
                                    <img src="/assets/img/achievement/1.jpg" class="img-fluid w-100" alt="Achievement">
                                </div>
                                <div class="card-body">
                                    <h3>SOAR - New Student Orientation</h3>
                                    <p>Student Orientation and Registration (SOAR) | On Campus</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>