<div id="infrastructure">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                    <h4 class="card-title">Internet Facility</h4>
                        <p class="inner-lead">The institute has one Air Conditioned computer lab with latest hardware and software utilities. The lab has printer which comprise of Laser Inkjet & Dot Matrix Printers.</p>
                        <p class="inner-lead">Number and Configuration of Systems: P4 – 50</p>
                        <p class="inner-lead">Total number of systems connected by LAN: 50</p>
                        <p class="inner-lead">Internet bandwidth: Broadband B. S. N. L <b>16Mbps</b></p>
                        <p class="inner-lead">Major software packages available: MS Window-2007, MS Office 2007, Antivirus, C language</p>
                    </div>
                </div>
            </div>
        </div>