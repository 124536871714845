<div id="infrastructure">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                    <h4 class="card-title">Animal House</h4>
                        <div class="row text-center">
                            <div class="col-12 col-md-6 mb-3"><img src="/assets/img/infra/animal1.jpg" class="img-fluid rounded" alt=""></div>
                            <div class="col-12 col-md-6 mb-3"><img src="/assets/img/infra/animal2.jpg" class="img-fluid rounded" alt=""></div>
                        </div>
                        <p class="inner-lead">The college has a CPCSEA recognized animal house facility. The animals are housed strictly as per CPCSEA guidelines. Institutional Animal Ethics Committee, comprising of eminent scientist, veterinarians along with nominated members of CPCSEA is in place.</p>
                    </div>
                </div>
            </div>
</div>