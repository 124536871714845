<div id="header">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-md-6 d-flex align-items-center first">
                <div class="logo">
                    <a routerLink="/"><img src="/assets/img/logo/5.png" alt="Logo" class="img-fluid"></a>
                </div>
            </div>
            <div class="col-12 col-md-6 pe-0 second">
                <div id="top">
                    <ul class="nav nav-pills">
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button"
                                aria-expanded="false">Achievements</a>
                            <ul class="dropdown-menu">
                                <li><a class="dropdown-item" routerLink="/achievement/reward">Rewards</a></li>
                                <li><a class="dropdown-item" routerLink="/achievement/scholarship">Scholarship</a></li>
                            </ul>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" role="button" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                                Admission
                            </a>
                            <ul class="dropdown-menu">
                                <li><a class="dropdown-item" href="/assets/pdf/Admission Form.pdf" target="_blank">Admission Form</a></li>
                                <li><a class="dropdown-item" routerLink="/admission/course">Course Offered</a></li>
                                <li><a class="dropdown-item" routerLink="/admission/status">Admission Status</a></li>
                                <li><a class="dropdown-item" routerLink="/admission/fee">Fee Detail</a></li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/administration">Administration</a>
                        </li>
                        <!-- <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" role="button" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                                Faculty
                            </a>
                            <ul class="dropdown-menu">
                                <li><a class="dropdown-item" routerLink="/faculty/teaching">Teaching</a></li>
                                <li><a class="dropdown-item" routerLink="/faculty/non-teaching">Non-Teaching</a></li>
                            </ul>
                        </li> -->
                        <li class="nav-item">
                            <a class="nav-link" href="https://drive.google.com/file/d/1CvqnfP74d4pEF9oYDxLtu3YxzOpHj_5L/view?usp=drive_link" target="_blank">SIF 2025-26</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/contact-us">Contact Us</a>
                        </li>
                    </ul>
                </div>
                <div id="menu-header">
                    <nav class="navbar navbar-expand-xl pt-0">
                        <div class="container-fluid">
                            <button class="navbar-toggler mt-3 ms-auto" type="button" data-bs-toggle="collapse"
                                data-bs-target="#menu" aria-controls="menu" aria-expanded="false"
                                aria-label="Toggle navigation">
                                <span class="navbar-toggler-icon"></span>
                            </button>
                            <div class="collapse navbar-collapse" id="menu">
                                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                                    <li class="nav-item align-items-xl-center d-xl-flex">
                                        <a class="nav-link active" aria-current="page" routerLink="/"><i class="fa-solid fa-house"></i></a>
                                    </li>
                                    <li class="nav-item align-items-xl-center d-xl-flex dropdown">
                                        <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            About Us
                                        </a>
                                        <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                            <li><a class="dropdown-item" routerLink="/about/history">History</a></li>
                                            <li><a class="dropdown-item" routerLink="/about/president">President's Message</a></li>
                                            <li><a class="dropdown-item" routerLink="/about/principal">Principal's Message</a></li>
                                            <li><a class="dropdown-item" routerLink="/about/objective">Objective</a></li>
                                            <li><a class="dropdown-item" routerLink="/about/mission">Vision & Mission</a></li>
                                            <li><a class="dropdown-item" routerLink="/about/affiliation">Affiliation and Approval</a></li>
                                            <li><a class="dropdown-item" routerLink="/about/governing">Governing body</a></li>
                                        </ul>
                                    </li>
                                    <li class="nav-item align-items-xl-center d-xl-flex dropdown">
                                        <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            Infrastructure
                                        </a>
                                        <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                            <li><a class="dropdown-item" routerLink="/infra/computer">Computer Lab</a></li>
                                            <li><a class="dropdown-item" routerLink="/infra/labs">Specialized Lab</a></li>
                                            <li><a class="dropdown-item" routerLink="/infra/library">Library</a></li>
                                            <li><a class="dropdown-item" routerLink="/infra/garden">Herbal Garden</a></li>
                                            <li><a class="dropdown-item" routerLink="/infra/conference">Conference Room</a></li>
                                            <li><a class="dropdown-item" routerLink="/infra/hostel">Hostel Facility</a></li>
                                            <li><a class="dropdown-item" routerLink="/infra/cafeteria">Cafeteria</a></li>
                                            <li><a class="dropdown-item" routerLink="/infra/internet">Internet Facility</a></li>
                                            <li><a class="dropdown-item" routerLink="/infra/sports">Sports Centre</a></li>
                                            <li><a class="dropdown-item" routerLink="/infra/animal">Animal House</a></li>
                                        </ul>
                                    </li>
                                    <li class="nav-item align-items-xl-center d-xl-flex dropdown">
                                        <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            Committees /Cells
                                        </a>
                                        <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                            <li><a class="dropdown-item" routerLink="/cells/admission">Admission Cell</a></li>
                                            <li><a class="dropdown-item" routerLink="/cells/anti-ragging">Anti Ragging Cell</a></li>
                                            <li><a class="dropdown-item" routerLink="/cells/anti-squad">Anti Squad Cell</a></li>
                                            <li><a class="dropdown-item" routerLink="/cells/anti-discrimination">Anti Discrimination Cell</a></li>
                                            <li><a class="dropdown-item" routerLink="/cells/gender">Gender Sensitization Cell</a></li>
                                            <li><a class="dropdown-item" routerLink="/cells/discipline">Discipline Cell</a></li>
                                            <li><a class="dropdown-item" routerLink="/cells/grievance">Grievance Redressal Cell</a></li>
                                            <li><a class="dropdown-item" routerLink="/cells/innovation">Innovation Cell</a></li>
                                            <li><a class="dropdown-item" routerLink="/cells/industry">Institute Industry Cell</a></li>
                                            <li><a class="dropdown-item" routerLink="/cells/icc">Internal Complaint Committee</a></li>
                                            <li><a class="dropdown-item" routerLink="/cells/iqac">Internal Quality Assurance Cell</a></li>
                                            <li><a class="dropdown-item" routerLink="/cells/media">Media Cell</a></li>
                                            <li><a class="dropdown-item" routerLink="/cells/research">Research Cell</a></li>
                                            <li><a class="dropdown-item" routerLink="/cells/harrasment">Sexual Harrasment Cell</a></li>
                                            <li><a class="dropdown-item" routerLink="/cells/sports">Sports Cell</a></li>
                                            <li><a class="dropdown-item" routerLink="/cells/counselor">Students Counselor</a></li>
                                        </ul>
                                    </li>
                                    <li class="nav-item align-items-xl-center d-xl-flex dropdown">
                                        <a class="nav-link dropdown-toggle border-0" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            Faculty
                                        </a>
                                        <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                            <li><a class="dropdown-item" routerLink="/faculty/teaching">Teaching</a></li>
                                            <li><a class="dropdown-item" routerLink="/faculty/non-teaching">Non-Teaching</a></li>
                                        </ul>
                                    </li>
                                    
                                    <!-- <li class="nav-item align-items-xl-center d-xl-flex enquiry-now">
                                        <a class="nav-link btn" href="" target="_blank">Enquiry Now</a>
                                    </li> -->
                                </ul>
                                <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                                    <li class="nav-item align-items-xl-center d-xl-flex enquiry-now float-end">
                                        <a routerLink="/registration" class="nav-link btn">APPLY NOW</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div>
<a class="btn mandatory blink" routerLink="/registration">Admission Open</a>