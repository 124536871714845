import { Component, OnInit } from '@angular/core';
import { FacultyService } from 'src/app/services/faculty/faculty.service';

@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.scss']
})
export class AdministrationComponent implements OnInit {
  faculty:any=[];
  constructor(private service: FacultyService) { }

  ngOnInit(): void {
    this.faculty = this.service.getFaculty();
    console.log(this.faculty);
  }

}
