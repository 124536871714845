<div id="cells">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <h4 class="card-title">Anti Squad Cell</h4>

                <div class="table-responsive">
                    <table class="table table-bordered table-hover">
                        <thead class="thead-dark">
                            <tr>
                                <th>Sr. No.</th>
                                <th>Name of Member</th>
                                <th>Designation</th>
                                <th>Mobile No.</th>
                                <th>Email ID</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let member of members">
                                <td>{{ member.srNo }}</td>
                                <td>{{ member.name }}</td>
                                <td>{{ member.designation }}</td>
                                <td>{{ member.mobile }}</td>
                                <td>{{ member.email }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <!-- <div class="text-center"><img src="/assets/img/cells/anti-squad.jpg" class="img-fluid rounded-4"
                        alt="Admission Status"></div> -->
            </div>
        </div>
    </div>
</div>