<div id="about">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <h4 class="card-title">Affiliation and Approval</h4>
                <h4>Approvals</h4>
                <p class="inner-lead">The college is approved by</p>
                <ul>
                    <li>
                        <p class="inner-lead">AICTE, New Delhi</p>
                    </li>
                    <li>
                        <p class="inner-lead">PCI New Delhi</p>
                    </li>
                </ul>
                <h4>Affiliations</h4>
                <ul>
                    <li>
                        <p class="inner-lead">Diploma of Pharmacy is Affiliated by State Board of Technical
                            Education, Panchkula, Haryana .</p>
                    </li>
                    <li>
                        <p class="inner-lead">Bachelor of Pharmacy is affiliated by Pt. Bhagwat Dayal Sharma
                            University of Health Sciences, Rohtak.</p>
                    </li>
                </ul>
                <p class="inner-lead">Master of Pharmacy is affiliated by Pt. Bhagwat Dayal Sharma University of
                    Health Sciences, Rohtak.</p>
            </div>
        </div>
    </div>
</div>