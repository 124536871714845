<!-- <app-banner></app-banner> -->
<div id="contact">
    <section class="contact-page-section">
        <div class="container">
            <div class="alert alert-success alert-dismissible fade show" role="alert" *ngIf="showAlert">
                We received Your Query. Contact You Shortly!
                <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="showAlert=false">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="showDanger">
                There is some issue in our end. Please Try Again!
                <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="showDanger=false">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="sec-title">
                <div class="title">Contact Us</div>
                <h2>Let's Get in Touch.</h2>
            </div>
            <div class="inner-container">
                <div class="row clearfix">

                    <!--Form Column-->
                    <div class="form-column col-md-8 col-sm-12 col-xs-12">
                        <div class="inner-column">

                            <!--Contact Form-->
                            <div class="contact-form">
                                <form id="contact-form" #contactForm="ngForm" (ngSubmit)="onSubmit(contactForm)"
                                    class="row g-3 needs-validation" novalidate>
                                    <div class="row clearfix">
                                        <div class="form-group col-md-6 col-sm-6 co-xs-12">
                                            <input type="text" value="" placeholder="Name" name="name" ngModel id="name"
                                                minlength="2" required>
                                            <div class="valid-feedback">
                                                Looks good!
                                            </div>
                                            <div class="invalid-feedback">
                                                Please Give Your Name.
                                            </div>
                                        </div>
                                        <div class="form-group col-md-6 col-sm-6 co-xs-12">
                                            <input value="" placeholder="Phone" type="tel" name="phone" id="phone"
                                                required minlength="10" maxlength="10" ngModel>
                                            <div class="valid-feedback">
                                                Looks good!
                                            </div>
                                            <div class="invalid-feedback">
                                                Please Give 10 digit Mobile Number.
                                            </div>
                                        </div>
                                        <div class="form-group col-md-12 col-sm-6 co-xs-12">
                                            <input type="email" value="" placeholder="Email" name="email" id="email"
                                                required email ngModel>
                                            <div class="valid-feedback">
                                                Looks good!
                                            </div>
                                            <div class="invalid-feedback">
                                                Please Give your Email Id.
                                            </div>
                                        </div>
                                        <!-- <div class="form-group col-md-6 col-sm-6 co-xs-12">
                                              <input type="text" name="subject" value="" placeholder="Subject" required>
                                          </div> -->

                                        <div class="form-group col-md-12 col-sm-12 co-xs-12">
                                            <textarea placeholder="Message" name="message" id="message" required
                                                minlength="3" ngModel></textarea>
                                            <div class="valid-feedback">
                                                Looks good!
                                            </div>
                                            <div class="invalid-feedback">
                                                Please Give Your Query.
                                            </div>
                                        </div>
                                        <div class="form-group col-md-12 col-sm-12 co-xs-12">
                                            <button type="submit" [disabled]="!contactForm.valid"
                                                class="theme-btn btn-style-one">Send Now</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <!--End Contact Form-->

                        </div>
                    </div>

                    <!--Info Column-->
                    <div class="info-column col-md-4 col-sm-12 col-xs-12">
                        <div class="inner-column">
                            <h2>Contact Info</h2>
                            <ul class="list-info">
                                <li class="text-uppercase"><i class="fas fa-globe"></i>HINDU COLLEGE OF PHARMACY
                                    Near Panchayat Bhawan, Gohana Road, Sonipat, Haryana 131001</li>
                                <li><i class="far fa-envelope"></i>hindupharmacycolllege@gmail.com</li>
                                <li><i class="fas fa-phone"></i>0130-2221072, 2221568
                                </li>
                            </ul>
                            <ul class="social-icon-four">
                                <li class="follow">Follow on: </li>
                                <li><a href="" target="_blank"><i
                                            class="fab fa-facebook-f"></i></a></li>
                                <li><a href="" target="_blank"><i
                                            class="fab fa-instagram"></i></a></li>
                                <li><a href="" target="_blank"><i
                                            class="fab fa-twitter"></i></a></li>
                                <li><a href="" target="_blank"><i
                                            class="fab fa-youtube"></i></a></li>
                                <li><a href="" target="_blank"><i
                                            class="fas fa-map-pin"></i></a></li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</div>