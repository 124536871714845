<div id="bulletin" class="my-5">
    <div class="container ">
        <div class="bullettin-card mx-auto">
            <div class="row bullet bg-light">
                <div class="col-5 col-md-4 col-xl-2 bullet-1">
                    <h5 class="text-center">Announcements</h5>
                    <span class=""></span>
                </div>
                <div class="col-7 col-md-8 col-xl-10 bullet-2">
                    <marquee behavior="scroll" direction="left" class="bulletinMarque">Applications Open For Academic
                        Session 2025-26</marquee>
                </div>
            </div>
        </div>
    </div>
</div>