import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-governing',
  templateUrl: './governing.component.html',
  styleUrls: ['./governing.component.scss']
})
export class GoverningComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
