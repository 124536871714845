import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AboutComponent } from 'src/app/screens/about/about.component';
import { AffiliationComponent } from 'src/app/screens/about/affiliation/affiliation.component';
import { ChairmanComponent } from 'src/app/screens/about/chairman/chairman.component';
import { GoverningComponent } from 'src/app/screens/about/governing/governing.component';
import { HistoryComponent } from 'src/app/screens/about/history/history.component';
import { ObjectiveComponent } from 'src/app/screens/about/objective/objective.component';
import { PrincipalComponent } from 'src/app/screens/about/principal/principal.component';
import { VisionComponent } from 'src/app/screens/about/vision/vision.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [
    AboutComponent,
    HistoryComponent,
    ChairmanComponent,
    PrincipalComponent,
    ObjectiveComponent,
    VisionComponent,
    GoverningComponent,
    AffiliationComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild([
      {
        path: '', component: AboutComponent, children: [
          { path: 'history', component: HistoryComponent },
          { path: 'principal', component: PrincipalComponent },
          { path: 'president', component: ChairmanComponent },
          { path: 'objective', component: ObjectiveComponent },
          { path: 'vision', component: VisionComponent },
          { path: 'governing', component: GoverningComponent },
          { path: 'affiliation', component: AffiliationComponent },
        ]
      }
    ])
  ]
})
export class AboutModule { }
