<div id="infra" class="py-5">
    <div class="container">
        <div class="row">
            <div class="col-12 text-center">
                <h2 class="home-title mb-0">Infrastructure at a Glance</h2>
                <p>Explore the state-of-the-art infrastructure and facilities at Hindu College Of Pharmacy, designed to
                    foster academic excellence and holistic development.</p>
            </div>
        </div>

        <div class="row mt-5">
            <owl-carousel-o [options]="customOptions">

                <ng-container *ngFor="let data of infra; let i=index">
                    <ng-template carouselSlide>
                        <div class="card1" (click)="redirect(data.route)">
                            <div class="card-img text-center w-100">
                                <img [src]="data.imgUrl" class="img-fluid" alt="">
                            </div>
                            <div class="card-body text-center"  [ngClass]="i%2 !== 0 ? 'primaryBg' : ''">
                                <h3 class="card-title mb-3">{{ data.title }}</h3>
                                <p>{{ data.desc }}</p>
                                <div class="icon"><i class="fa-solid fa-arrow-right"></i>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </ng-container>
            </owl-carousel-o>
        </div>
    </div>
</div>