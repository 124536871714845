import { Component, OnInit } from '@angular/core';
declare var $:any;

@Component({
  selector: 'app-showcase',
  templateUrl: './showcase.component.html',
  styleUrls: ['./showcase.component.scss']
})
export class ShowcaseComponent implements OnInit {
  isLoading: boolean[] = [];
  imgUrl = [
    '/assets/img/showcase/1.jpeg',
    '/assets/img/home-about/1.jpg',
    '/assets/img/home-about/0.jpg',
    '/assets/img/showcase/6.jpg',
  ]

  slider:{title:string,imgUrl:string}[]=[
    {
      title:'Where Dreams Become Reality',
      imgUrl:'/assets/img/home-about/1.jpg'
    },
    {
      title:'Learning Today, For Leading Tomorrow',
      imgUrl:'/assets/img/showcase/1.jpeg'
    },
    {
      title:'We raise leaders for the future',
      imgUrl:'/assets/img/home-about/0.jpg'
    },
    {
      title:'Stop Not, Till the goal is reached',
      imgUrl:'/assets/img/showcase/6.jpg'
    },
    {
      title:'The Future Awaits',
      imgUrl:'/assets/img/showcase/7.jpg'
    },
    {
      title:'Vision is everything',
      imgUrl:'/assets/img/showcase/8.jpg'
    },
    {
      title:'Action is the foundational key to all success',
      imgUrl:'/assets/img/showcase/9.jpg'
    },
    {
      title:'Education is the best provision for life’s journey',
      imgUrl:'/assets/img/showcase/10.jpg'
    },
    {
      title:'Life is all about having a good time',
      imgUrl:'/assets/img/showcase/11.jpg'
    },
  ]
  constructor() { }

  ngOnInit(): void {
    this.setLoader();
    $('.carousel').carousel({
      pause: false
    })
  }
  setLoader() {
    for (let i = 0; i < this.imgUrl.length; i++) {
      this.isLoading.push(true);
    }
  }
  hideLoader(i: number) {
    this.isLoading[i] = false;
  }
}
