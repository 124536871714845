<div id="infrastructure">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <h4 class="card-title">Library</h4>
                <div class="row text-center">
                    <div class="col-12 col-md-6 mb-3"><img src="/assets/img/infra/lib1.jpg" class="img-fluid rounded"
                            alt=""></div>
                    <div class="col-12 col-md-6 mb-3"><img src="/assets/img/infra/lib2.jpg" class="img-fluid rounded"
                            alt=""></div>
                    <div class="col-12 col-md-6 mb-3"><img src="/assets/img/infra/lib3.jpg" class="img-fluid rounded"
                            alt=""></div>
                    <div class="col-12 col-md-6 mb-3"><img src="/assets/img/infra/lib4.jpg" class="img-fluid rounded"
                            alt=""></div>
                </div>
                <p class="inner-lead">Library provides a house of relevant books, reports, magazines, newspapers,
                    news-letters, pharmacopoeias etc.</p>
                <p class="inner-lead">It has a well maintained collection of almost 2400 titles and 24000 pharmacy
                    books. The institute has subscription to around 30 national and international journals with
                    e-journal subscription of DELNET.</p>
                <p class="inner-lead">It has a reading hall with capacity of around 100 students.</p>
            </div>
        </div>
    </div>
</div>