<div class="container pt-5">
    <div class="row">

        <div class="col-12 col-sm-12 col-md-6 col-lg-6  mb-4">

            <div class="image text-center">
                <img src="/assets/img/message/president.jpg" alt="President" class="img-fluid pre my-2">
            </div>
        </div>

        <div class="col-12 col-md-6 col-lg-6 pe-lg-5 mb-4">
            <h2 class="home-title">President's Message</h2>
            <p class="mb-2 mt-4">The Sonipat Hindu Educational & Charitable Society has always prioritized equipping
                students with the skills needed to thrive in today’s evolving professional world. With a
                focus on advanced learning, cutting-edge facilities, and a culture of academic
                excellence, the Society is dedicated to supporting students in achieving successful
                placements and impactful careers.Hindu College of Pharmacy was founded with the vision of cultivating
                future healthcare
                leaders by imparting essential knowledge that drives both personal growth and societal
                advancement.

            </p>
            <div class="readMore text-center">
                <a routerLink="/about/president" class="btn px-5">Read More &nbsp; &nbsp;<i
                        class="fa-solid fa-arrow-right"></i></a>
            </div>
        </div>
    </div>
</div>

<div class="container pt-5">
    <div class="row">
        
        <div class="col-12 col-sm-12 col-md-6 col-lg-7 mb-4">
            <h2 class="home-title">Principal's Message</h2>
            <p class="mb-2 mt-4">Hindu College of Pharmacy stands as a leading institution, recognized for its
                excellence
                in both academics and extracurricular endeavours. Our college offers a balanced blend
                of theoretical learning and practical application, with a strong emphasis on industry
                relevance. Hindu College of Pharmacy plays a pivotal role in fostering an environment
                where students can deepen their understanding of healthcare foundations while
                developing a confident and proactive personality.Guided by a strong value system, we emphasize that
                alongside gaining professional and
                entrepreneurial skills, our students embody values of integrity, dedication, social
                justice, and compassion.
            </p>
            <div class="readMore text-center">
                <a routerLink="/about/principal" class="btn px-5">Read More &nbsp; &nbsp;<i
                        class="fa-solid fa-arrow-right"></i></a>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-5 pe-lg-5 mb-4">
            <div class="image text-center">
                <img src="/assets/img/message/principal.jpg" alt="Principal" class="img-fluid pre mt-3">
            </div>
        </div>
    </div>
</div>