import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-infra',
  templateUrl: './infra.component.html',
  styleUrls: ['./infra.component.scss']
})
export class InfraComponent implements OnInit {
  customOptions: OwlOptions = {
    loop: true,
    margin: 15,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 300,
    navText: ['<', '>'],
    autoplay: true,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 2
      },
      940: {
        items: 2
      },
      1160: {
        items: 3
      }
    },
    nav: true
  }

  infra: any[] = [
    {
      imgUrl: '/assets/img/infra/classroom.jpg',
      title: 'Class Rooms',
      desc: 'The Smart Classroom at Hindu College Of Pharmacy integrates advanced technology with traditional teaching methods, providing an interactive and dynamic learning environment. Equipped with digital projectors, audio-visual tools, and smart boards, the classroom enhances engagement...',
      route: '/infra'
    },
    {
      imgUrl: '/assets/img/infra/lab1.jpg',
      title: 'Laboratories',
      desc: 'Separate fully equipped and spacious Practical Science Labs for Physics, Chemistry, Computer Sci. Instrumentation, Electronics, Bio-Technology, Botany, Zoology, Language, Geography & Defence Studies are provided to the students and staff to develop their manipulative...',
      route: '/infra/labs'
    },
    {
      imgUrl: '/assets/img/infra/lib2.jpg',
      title: 'Library',
      desc: 'The college library is situated in the heart of the college campus. Consisting of three beautifully built halls, it provides comfortable sitting to over 250 students. The library has adequate space, furniture, reading chairs, tables, display racks, magazine racks etc. The library provides internet facility...',
      route: '/infra/library'
    },
    {
      imgUrl: '/assets/img/infra/comp1.jpg',
      title: 'Computer Lab',
      desc: 'With the introduction of a number of revised and restructured courses, computers have become an integral part of the undergraduate colleges curriculum. The Central Computer Laboratory in college will provide you with an opportunity by enhancing your computer skills.',
      route: '/infra/computer'
    },
    {
      imgUrl: '/assets/img/infra/animal1.jpg',
      title: 'Animal House',
      desc: 'The college has a CPCSEA recognized animal house facility. The animals are housed strictly as per CPCSEA guidelines. Institutional Animal Ethics Committee, comprising of eminent scientist, veterinarians along with nominated members of CPCSEA is in place...',
      route: '/infra/animal'
    },
    // {
    //   imgUrl: '/assets/img/infra/sports.jpg',
    //   title: 'Sports',
    //   desc: 'Another prominent feature of the college is the promotion of sports. In this respect the college is widely acknowledged on account of its remarkable achievements in this area. It had the distinction of remaining K.U. champion in Boxing for more than 20 years. Its performance in Kabaddi...',
    //   route: '/infra/sports'
    // },
  ];

  constructor() { }

  ngOnInit(): void {
  }

  redirect(url: string) {
    window.open(url);
  }
}
