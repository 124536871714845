import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from 'src/app/screens/shared/loader/loader.component';
import { BannerComponent } from 'src/app/screens/shared/banner/banner.component';
import { FacultyItemComponent } from 'src/app/screens/shared/faculty-item/faculty-item.component';



@NgModule({
  declarations: [
    LoaderComponent,
    BannerComponent,
    FacultyItemComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    LoaderComponent,
    BannerComponent,
    FacultyItemComponent
  ]
})
export class SharedModule { }
