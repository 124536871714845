<div class="container-fluid mt-4">
    <div class="row g-4">
        <h4 class="card-title">Teaching Faculty</h4>

        <!-- Faculty Cards Loop -->
        <div class="col-md-6" *ngFor="let faculty of facultyList">
            <div class="card p-4 shadow-lg border-0 rounded-4 h-100" style="background: #fff;">
                <div class="d-flex align-items-center">
                    <!-- Profile Image -->
                    <div class="text-center me-3">
                        <img [src]="faculty.image" [alt]="faculty.name"
                            class="img-fluid rounded-3 shadow-sm"
                            style=" border: 4px solid #f0f0f0;" />
                    </div>
                    <!-- Faculty Details -->
                    <div>
                        <ul class="list-unstyled mb-0">
                            <li><p class="mb-1"><strong class="text-dark">Name:</strong> {{ faculty.name }}</p></li>
                            <li><p class="mb-1"><strong class="text-dark">Designation:</strong> {{ faculty.designation }}</p></li>
                            <li><p class="mb-1"><strong class="text-dark">Qualification:</strong> {{ faculty.qualification }}</p></li>
                            <li><p class="mb-1"><strong class="text-dark">Experience:</strong> {{ faculty.experience }}</p></li>
                            <li>
                                <p class="mb-1"><strong class="text-dark">Email:</strong> 
                                    <a [href]="'mailto:' + faculty.email" class="text-success fw-bold text-decoration-none">
                                        {{ faculty.email }}
                                    </a>
                                </p>
                            </li>
                            <li>
                                <p class="mb-1"><strong class="text-dark">Autobiography: </strong> 
                                    <a [href]="faculty.autobiography" class="text-primary fw-bold text-decoration-none" target="_blank">Download</a>
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
