import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FacultyService {
  private faculty: any[] = [
    {
      "name": "Dr. Arun Mittal",
      "designation": "Principal",
      "qual": "M. Pharm, PhD",
      "img": "/assets/img/faculty/1.jpg",
      "resume": "https://drive.google.com/file/d/1DwmsHR1MsvI2sgnqNvX8H2SckMHqPzpO/view?usp=sharing"
    },
    {
      "name": "Dr. Dinesh Kaushik",
      "designation": "Professor",
      "qual": "M. Pharm, PhD",
      "img": "/assets/img/faculty/2.jpg",
      "resume": "https://drive.google.com/file/d/1J7IOglfhXtHfZld0768exvB4xR589Gx2/view?usp=sharing"
    },
    {
      "name": "Dr. Bharat Bhushan",
      "designation": "Associate Professor",
      "qual": "M. Pharm, PhD",
      "img": "/assets/img/faculty/3.jpg",
      "resume": "https://drive.google.com/file/d/1qFoNr3bbP_7yzSIZOBMnE83qOSm-_yy6/view?usp=sharing"
    },
    {
      "name": "Mr. Chetan Vashisht",
      "designation": "Associate Professor",
      "qual": "M. Pharm, PhD (Pursuing)",
      "img": "/assets/img/faculty/4.png",
      "resume": ""
    },
    {
      "name": "Dr. Jyoti ",
      "designation": "Assistant Professor",
      "qual": "M. Pharm, PhD",
      "img": "/assets/img/faculty/user.svg",
      "resume": "https://drive.google.com/file/d/1taqPi2-iLbgJmVw17zPy1DrhzAkoMcQa/view?usp=sharing"
    },
    {
      "name": "Dr. Preeti",
      "designation": "Assistant Professor",
      "qual": "M. Pharm, PhD",
      "img": "/assets/img/faculty/6.jpg",
      "resume": "https://drive.google.com/file/d/1yPYYGrsPT75x-JvL3G7wWh3di8dpXAVg/view?usp=sharing"
    },
    {
      "name": "Mr. Sangeet ",
      "designation": "Assistant Professor",
      "qual": "M. Pharm, PhD (Pursuing)",
      "img": "/assets/img/faculty/7.jpg",
      "resume": "https://drive.google.com/file/d/1zjsdsU4N1d5IZ5oF6wHIGe6zKHCgYosC/view?usp=sharing"
    },
    {
      "name": "Mr Vishnu Dutt",
      "designation": "Assistant Professor",
      "qual": "",
      "img": "/assets/img/faculty/8.jpg",
      "resume": "https://drive.google.com/file/d/1nUnTH3j4Ye38U33CWAgxDwNOdotI31NR/view?usp=sharing"
    },
    {
      "name": "Mr Naveen",
      "designation": "Assistant Professor",
      "qual": "M. Pharm, PhD (Pursuing)",
      "img": "/assets/img/faculty/9.png",
      "resume": "https://drive.google.com/file/d/1NFvQ-83jam0-vorrPbVVjSgPW7UNMtXf/view?usp=sharing"
    },
    {
      "name": "Ms. Priyanka Sharma",
      "designation": "Assistant Professor",
      "qual": "M. Pharm, PhD (Pursuing)",
      "img": "/assets/img/faculty/10.jpg",
      "resume": "https://drive.google.com/file/d/1zjXUtPyxZcvIFTlkSJtUtgy8Y6b7d6Gx/view?usp=sharing"
    },
    {
      "name": "Mrs. Shilpa Jain",
      "designation": "Assistant Professor",
      "qual": "M. Pharm",
      "img": "/assets/img/faculty/11.jpg",
      "resume": "https://drive.google.com/file/d/1qqC40ffYRLOSrX0-GfVuG9capTT0tra3/view?usp=sharing"
    },
    {
      "name": "Mr. Shivam Rajput",
      "designation": "Assistant Professor",
      "qual": "M. Pharm, PhD (Pursuing)",
      "img": "/assets/img/faculty/12.jpg",
      "resume": "https://drive.google.com/file/d/1gmF1Y27e39kviCJRdrj1BmVOJVpcxqdF/view?usp=sharing"
    },
    {
      "name": "Mrs. Ittishree",
      "designation": "Assistant Professor",
      "qual": "M. Pharm, PhD (Pursuing)",
      "img": "/assets/img/faculty/user.svg",
      "resume": "https://drive.google.com/file/d/1xapUcXKw6qdSk4i7_oG89w27AT3dIpgR/view?usp=sharing"
    },
    {
      "name": "Sh. Birender Singh",
      "designation": "Assistant Professor",
      "qual": "M. Pharm",
      "img": "/assets/img/faculty/14.jpg",
      "resume": "https://drive.google.com/file/d/1pw9pOP9a44ZM6zBLafKS3n9dOSya2CGI/view?usp=sharing"
    },
    {
      "name": "Sh. Vineet Vashisht",
      "designation": "Assistant Professor",
      "qual": "M. Pharm",
      "img": "/assets/img/faculty/15.jpg",
      "resume": "https://drive.google.com/file/d/1en_eNUdusZiRKRkQGVgqevDeuboBLzK7/view?usp=sharing"
    },
    {
      "name": "Mrs. Reetu Rani",
      "designation": "Assistant Professor",
      "qual": "M. Pharm",
      "img": "/assets/img/faculty/16.jpg",
      "resume": "https://drive.google.com/file/d/10y1S_YmSYYW-zHQoSi34AnnItGZXAmeW/view?usp=sharing"
    },
    {
      "name": "Mrs. Jyoti",
      "designation": "Assistant Professor",
      "qual": "M. Pharm, PhD (Pursuing)",
      "img": "/assets/img/faculty/user.svg",
      "resume": "https://drive.google.com/file/d/1fQSQ7arSGYU3P6-q_59qEjnR2DwkBn6X/view?usp=sharing"
    },
    {
      "name": "Ms. Swati Saini",
      "designation": "Assistant Professor",
      "qual": "M. Pharm, PhD (Pursuing)",
      "img": "/assets/img/faculty/18.jpg",
      "resume": "https://drive.google.com/file/d/1Ym-U5AiPd-iao6jgRCu3C8AL277o6l79/view?usp=sharing"
    },
    {
      "name": "Ms. Ruchi Bansal",
      "designation": "Assistant Professor",
      "qual": "M. Pharm",
      "img": "/assets/img/faculty/19.jpg",
      "resume": "https://drive.google.com/file/d/1DucAf2AKW_4WpBcxmSLLy3WrTy2toX9P/view?usp=sharing"
    },
    {
      "name": "Ms. Shivani Sharma",
      "designation": "Assistant Professor",
      "qual": "M. Pharm, PhD (Pursuing)",
      "img": "/assets/img/faculty/user.svg",
      "resume": ""
    },
    {
      "name": "Mrs. Anju Rani",
      "designation": "Assistant Professor",
      "qual": "MBA, MCA",
      "img": "/assets/img/faculty/21.jpg",
      "resume": ""
    },
    {
      "name": "Mr. Ajesh Chauhan",
      "designation": "Assistant Professor",
      "qual": "M. Pharm, PhD (Pursuing)",
      "img": "/assets/img/faculty/22.jpg",
      "resume": "https://drive.google.com/file/d/1AzBwe0hDtVvgo9IroG-ig74w_afqryWb/view?usp=sharing"
    },
    {
      "name": "Ms. Sonam Rani",
      "designation": "Assistant Professor",
      "qual": "M. Pharmacy",
      "img": "/assets/img/faculty/user.svg",
      "resume": "https://drive.google.com/file/d/1XjSh3lBzxFWBjIg-TOAaINBIaTnZRw-J/view?usp=sharing"
    }
  ]
  constructor() { }

  getFaculty(){
    return this.faculty.slice();
  }
}
