import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-anti-squad',
  templateUrl: './anti-squad.component.html',
  styleUrls: ['./anti-squad.component.scss']
})
export class AntiSquadComponent implements OnInit {
  members = [
    { srNo: 1, name: 'Mr. Sangeet Kumar Sharma (Asst. Prof.)', designation: 'In-charge', mobile: '9813033239', email: 'sangeet.s.hcp@gmail.com' },
    { srNo: 2, name: 'Mr. Ajesh Chauhan (Asst. Prof.)', designation: 'Member', mobile: '9017074567', email: 'ajesh.c.hcp@gmail.com' },
    { srNo: 3, name: 'Mrs. Itishree (Asst. Prof.)', designation: 'Member', mobile: '9991829063', email: 'itishree.b.hcp@gmail.com' },
    { srNo: 4, name: 'Mr. Vineet Vashishth (Asst. Prof.)', designation: 'Member', mobile: '9467575400', email: 'vineet.v.hcp@gmail.com' },
    { srNo: 5, name: 'Mr. Birender Singh (Asst. Prof.)', designation: 'Member', mobile: '8168240910', email: 'birender.s.hcp@gmail.com' },
    { srNo: 6, name: 'Mrs. Reetu Rani (Asst. Prof.)', designation: 'Member', mobile: '9671195637', email: 'reetu.r.hcp@gmail.com' },
    { srNo: 7, name: 'Ms. Ruchi Bansal (Asst. Prof.)', designation: 'Member', mobile: '8168912611', email: 'ruchi.b.hcp@gmail.com' },
    { srNo: 8, name: 'Mr. Anil Kumar (Lab. Attendant)', designation: 'Member', mobile: '9996304680', email: 'hindupharmacycollege@gmail.com' },
    { srNo: 9, name: 'Mrs. Jyoti (Lab. Attendant)', designation: 'Member', mobile: '8607311852', email: 'hindupharmacycollege@gmail.com' }
  ];
  constructor() { }

  ngOnInit(): void {
  }

}
