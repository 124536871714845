import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './screens/header/header.component';
import { FooterComponent } from './screens/footer/footer.component';
import { HomeComponent } from './screens/home/home.component';
import { AboutUsComponent } from './screens/home/about-us/about-us.component';
import { BulletinComponent } from './screens/home/bulletin/bulletin.component';
import { ShowcaseComponent } from './screens/home/showcase/showcase.component';
import { SharedModule } from './modules/shared/shared.module';
import { WhyComponent } from './screens/home/why/why.component';
import { MissionComponent } from './screens/home/mission/mission.component';
import { HomeCoursesComponent } from './screens/home/home-courses/home-courses.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { MessagesComponent } from './screens/home/messages/messages.component';
import { FeatureComponent } from './screens/home/feature/feature.component';
import { InfraComponent } from './screens/home/infra/infra.component';
import { AchievementComponent } from './screens/home/achievement/achievement.component';
import { CounterComponent } from './screens/home/counter/counter.component';
import { NewsComponent } from './screens/home/news/news.component';
import { MapComponent } from './screens/footer/map/map.component';
import { AboutModule } from './modules/about/about.module';
import { AdmissionModule } from './modules/admission/admission.module';
import { ContactModule } from './modules/contact/contact.module';
import { AdministrationModule } from './modules/administration/administration.module';
import { CellsModule } from './modules/cells/cells.module';
import { InfraModule } from './modules/infra/infra.module';
import { RegistrationComponent } from './screens/registration/registration.component';
import { FacultyModule } from './modules/faculty/faculty.module';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    ShowcaseComponent,
    BulletinComponent,
    AboutUsComponent,
    WhyComponent,
    MissionComponent,
    HomeCoursesComponent,
    MessagesComponent,
    FeatureComponent,
    InfraComponent,
    AchievementComponent,
    CounterComponent,
    NewsComponent,
    MapComponent,
    RegistrationComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    CarouselModule,
    AboutModule,
    AdmissionModule,
    ContactModule,
    AdministrationModule,
    CellsModule,
    InfraModule,
    FacultyModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
